import config from 'config';

export const K_MEDIA_REVIEW_BERITA_SATU = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/beritasatu.png?auto=webp`,
  title:
    'Pelanggan Dekoruma bisa mendapatkan inspirasi desain interior terkini di Dekoruma Experience Center',
};
export const K_MEDIA_REVIEW_TRIBUN_NEWS = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/tribun.png?auto=webp`,
  title:
    'Dekoruma mempermudah siapa saja yang ingin mewujudkan rumah impian mereka',
};
export const K_MEDIA_REVIEW_DAILY_SOCIAL = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/daily-social.png?auto=webp`,
  title:
    'Situs Dekoruma per bulannya dikunjungi 2,5 juta kali dan memiliki lebih dari 520 ribu pengikut di Instagram. Pencapaian itu masih dilakukan secara organik dengan budget pemasaran yang minim',
  textStyle: {
    fontSize: 17,
    lineHeight: 22,
  },
};
export const K_MEDIA_REVIEW_LIPUTAN_6 = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/liputan6.png?auto=webp`,
  title:
    'Japanese Scandinavian, konsep desain rumah Dekoruma yang bisa penuhi kebutuhan milenial',
};
export const K_MEDIA_REVIEW_BEKRAF = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/bekraf.png?auto=webp`,
  title:
    'Dekoruma telah berkontribusi menciptakan kemudahan berkoneksi bagi pelaku kreatif bidang home & living dengan konsumen',
  textStyle: {
    fontSize: 17,
    lineHeight: 22,
  },
};
export const K_MEDIA_REVIEW_FORBES_1 = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/forbes.png?auto=webp`,
  title:
    'At Dekoruma, having a dream kitchen or bedroom is just a finger tap away, especially for urban people who do not have much time to take care of every detail to make their home cozier',
  textStyle: {
    fontSize: 17,
    lineHeight: 22,
  },
};
export const K_MEDIA_REVIEW_FORBES_2 = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/forbes.png?auto=webp`,
  title:
    'As a startup backed by strong technology, at Dekoruma designer & customer can pick furniture or materials for kitchens or bedrooms as easy as playing the Sims game',
  textStyle: {
    fontSize: 17,
    lineHeight: 22,
  },
};
export const K_MEDIA_REVIEW_FORBES_3 = {
  logoUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/media-logo/forbes.png?auto=webp`,
  title:
    'Dekoruma has aspired to remove the inefficiencies that have long occurred in the home & living industry',
};

export const K_MEDIA_REVIEWS = [
  K_MEDIA_REVIEW_BERITA_SATU,
  K_MEDIA_REVIEW_TRIBUN_NEWS,
  K_MEDIA_REVIEW_LIPUTAN_6,
  K_MEDIA_REVIEW_BEKRAF,
  K_MEDIA_REVIEW_FORBES_1,
];
