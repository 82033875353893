import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { FlatList, StyleSheet, View } from '@dkrm/ui-kit-basic';

import { slugify } from 'app-libs/etc';

import ExclusiveBrandItem from '../ExclusiveBrandItem';
import { K_EXCLUSIVE_BRAND_DATA } from '../constants';
import { BrandItem } from '../type';

const ExclusiveBrandCarousel: React.FC = memo(() => {
  return (
    <FlatList
      horizontal
      data={K_EXCLUSIVE_BRAND_DATA}
      contentContainerStyle={s.container}
      renderItem={({ item }: { item: BrandItem }) => (
        <ExclusiveBrandItem brandItem={item} />
      )}
      ItemSeparatorComponent={() => <View style={{ width: 12 }} />}
      initialNumToRender={4}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item: BrandItem) => `brand-item-${slugify(item.brand)}`}
    />
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.mtxl,
    ...cStyles.phxl,
    ...cStyles.mbm,
  },
});

export default ExclusiveBrandCarousel;
