import React, { useRef, useState } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';

import SearchBarPlaceholder from 'app-libs/components/Search/SearchBarPlaceholder';

import SearchSuggestionModal from './SearchSuggestionModal';
import styles from './styles';

interface SearchWithModalProps {
  onFocus?: Function;
  onBlur?: Function;
  searchBarPlaceholderProps?: TYPEFIXME;
}

const SearchWithModal: React.FC<SearchWithModalProps> = ({
  onFocus = () => undefined,
  onBlur = () => undefined,
  searchBarPlaceholderProps = {},
}) => {
  const searchBarRef = useRef(null);
  const [shouldShowSearchSuggestionModal, setShouldShowSearchSuggestionModal] =
    useState(false);

  const actionShowSearchBarSuggestionModal = () => {
    onFocus();
    setShouldShowSearchSuggestionModal(true);
  };

  const actionCloseSearchBarSuggestionModal = () => {
    onBlur();
    setShouldShowSearchSuggestionModal(false);
  };

  const actionFocusTextInput = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const searchBarContainerRef = searchBarRef.current?.searchBarBasicRef;
    const textInputRef = searchBarContainerRef.current?.textInputRef;
    if (textInputRef?.current) {
      textInputRef.current.focus();
    }
  };
  return (
    <>
      <SearchBarPlaceholder
        onPress={actionShowSearchBarSuggestionModal}
        containerStyle={cStyles.bgw}
        touchableStyle={styles.searchPlaceholderTouchable}
        inputTextStyle={styles.searchPlaceholderText}
        searchIconStyle={cStyles.pls}
        {...searchBarPlaceholderProps}
      />
      <SearchSuggestionModal
        visible={shouldShowSearchSuggestionModal}
        onShow={actionFocusTextInput}
        onSearchCancel={actionCloseSearchBarSuggestionModal}
        forwardRef={searchBarRef}
      />
    </>
  );
};

export default SearchWithModal;
