import React from 'react';
import PropTypes from 'prop-types';

import { ReflowView, Text, View, StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { formatSimplifiedPriceIDR } from 'app-libs/etc';
import { trackClickHouseLocationCard } from 'app-libs/trackers';

import {
  K_HOUSE_CATEGORY_CARD_WIDTH,
  K_HOUSE_CATEGORY_CARD_HEIGHT,
  K_HOUSE_CATEGORY_NUM_OF_COL,
} from '../constants';

const CityCard = ({ cityName, avgPriceSqm, linkUrl, imgUrl, imageProps }) => {
  const avgPriceSqmText = avgPriceSqm
    ? `Rata-rata\nRp ${formatSimplifiedPriceIDR(avgPriceSqm)}/m²`
    : '';
  const imgSource = {
    uri: imgUrl,
  };

  return (
    <ButtonWrapper
      to={linkUrl}
      linkProps={{ isUsingAnchor: true }}
      onPress={() => trackClickHouseLocationCard(linkUrl, cityName)}
    >
      {() => (
        <View style={s.container}>
          <ReflowView
            widthProportion={K_HOUSE_CATEGORY_CARD_WIDTH}
            heightProportion={K_HOUSE_CATEGORY_CARD_HEIGHT}
          >
            <SmartImage
              source={imgSource}
              sizes={`${(100 / K_HOUSE_CATEGORY_NUM_OF_COL) * 0.35}vw`}
              style={s.image}
              resizeMode="contain"
              accessibilityLabel="House Category City Card"
              {...imageProps}
            />
            <Text theme="h5-tosca" style={s.titleText}>
              {cityName}
            </Text>
            <Text theme="caption3-black60" style={s.subTitleText}>
              {avgPriceSqmText}
            </Text>
          </ReflowView>
        </View>
      )}
    </ButtonWrapper>
  );
};

CityCard.propTypes = {
  cityName: PropTypes.string.isRequired,
  avgPriceSqm: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  imageProps: PropTypes.shape().isRequired,
};

const s = StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.brs,
    backgroundColor: colors.C_BLACK_5,
  },
  image: {
    ...cStyles.abs,
    bottom: 0,
    right: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    transform: [{ scaleX: '-1' }],
    width: '35%',
    height: '35%',
  },
  titleText: {
    ...cStyles.mhxs,
    ...cStyles.phm,
    ...cStyles.ptm,
  },
  subTitleText: {
    ...cStyles.abs,
    ...cStyles.mlxs,
    ...cStyles.plm,
    ...cStyles.pbm,
    bottom: 3,
  },
});

export default React.memo(CityCard);
