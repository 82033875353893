import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { ReflowView, View, StyleSheet } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { getAdsByLocation } from 'app-libs/redux_modules/selectors/advertisement';
import { getBannersFromAdvertisements } from 'app-libs/etc/advertisementHelper';

import { Banner } from 'app-libs/redux_modules/entity_modules/advertisement/types';

import { K__STOREFRONT_MWEB__HOMEPAGE__SECONDARY_BANNER } from 'constants/advertisementConstants';

import BannerItem from './BannerItem';

const emptyBanner: Banner = {
  id: 0,
  imageUrl: '',
  miniImageInfo: null,
  name: '',
  textInfo: null,
  url: '',
};

const SecondaryBanner: React.FC = memo(() => {
  const secondaryBannerAds = useSelector((state) =>
    getAdsByLocation(state, K__STOREFRONT_MWEB__HOMEPAGE__SECONDARY_BANNER),
  );

  let bannersToShow = new Array(3).fill(emptyBanner);
  bannersToShow = getBannersFromAdvertisements(secondaryBannerAds);

  if (bannersToShow.length < 3) {
    return null;
  }

  return (
    <ReflowView widthProportion={4} heightProportion={1} style={s.container}>
      <View style={s.row}>
        <BannerItem key="secondary-banner-0" banner={bannersToShow?.[0]} />
        <BannerItem
          key="secondary-banner-1"
          banner={bannersToShow?.[1]}
          containerStyle={s.middleBanner}
          aspectRatio={1.8}
        />
        <BannerItem key="secondary-banner-2" banner={bannersToShow?.[2]} />
      </View>
    </ReflowView>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.mhxl,
    ...cStyles.mtxxl,
  },
  row: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
    justifyContent: 'space-between',
  },
  middleBanner: {
    ...cStyles.mhm,
    flex: 1.8,
  },
});

export default SecondaryBanner;
