import React, { memo, useCallback } from 'react';

import { StyleSheet, ReflowView, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import cStyles from '@dkrm/general-libs/theme/styles';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { getPathFromUrl } from 'app-libs/etc/routeHelper';
import { trackClickSecondaryBanner } from 'app-libs/trackers';

import { Banner } from 'app-libs/redux_modules/entity_modules/advertisement/types';
import { ViewStyle } from 'types/styles';

interface BannerItemProps {
  banner: Banner;
  containerStyle?: ViewStyle;
  aspectRatio?: number;
}

const BannerItem: React.FC<BannerItemProps> = memo(
  ({ banner, containerStyle, aspectRatio = 1 }) => {
    const { imageUrl, url } = banner;

    const handleBannerClicked = useCallback(() => {
      trackClickSecondaryBanner(url);
    }, [url]);

    if (!imageUrl) {
      return null;
    }

    return (
      <View style={[s.container, containerStyle]}>
        <Button
          to={getPathFromUrl(url)}
          onPress={handleBannerClicked}
          style={cStyles.height100}
        >
          {() => (
            <View style={cStyles.flex1}>
              <ReflowView widthProportion={aspectRatio} heightProportion={1}>
                <SmartImage
                  shouldPreloadSmallerVersion={false}
                  source={imageUrl ?? ''}
                  sizes="100vw"
                  style={cStyles.flex1}
                  resizeMode="contain"
                />
              </ReflowView>
            </View>
          )}
        </Button>
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.brs,
  },
  image: {
    ...cStyles.flex1,
    ...cStyles.brs,
  },
});

export default BannerItem;
