import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  Gradient,
  ReflowView,
  StyleSheet,
  Swiper,
  View,
} from '@dkrm/ui-kit-basic';

import { getBannersFromAdvertisements } from 'app-libs/etc/advertisementHelper';
import { getAdsByLocation } from 'app-libs/redux_modules/selectors/advertisement';

import { K__STOREFRONT_MWEB__HOMEPAGE__MAIN_BANNER } from 'constants/advertisementConstants';

import CampaignSliderItem from './Item';
import CampaignSliderSeeAllPromoButton from './SeeAllPromoButton';

export const K_BANNER_WIDTH_PROPORTION = 375;
export const K_BANNER_HEIGHT_PROPORTION = 300;

const CampaignSliderMain = memo(({ campaignSliderMainRef }) => {
  const mainBannerAds = useSelector((state) =>
    getAdsByLocation(state, K__STOREFRONT_MWEB__HOMEPAGE__MAIN_BANNER),
  );

  let bannersToShow = new Array(4).fill({});
  if (mainBannerAds.length > 0) {
    bannersToShow = getBannersFromAdvertisements(mainBannerAds);
  }

  const renderItem = useCallback(
    (item, index) => <CampaignSliderItem index={index} {...item} />,
    [],
  );

  return (
    <View>
      <Swiper
        ref={campaignSliderMainRef}
        data={bannersToShow}
        renderItem={renderItem}
        showSideNavigationButton={{
          showLeftSideNavigation: false,
          showRightSideNavigation: false,
        }}
        paginationIndicatorPosition="left"
        paginationTheme="white"
        paginationDotSize={6}
        paginationDotStyle={cStyles.maxs}
        paginationStyle={s.pagination}
        widthProportion={K_BANNER_WIDTH_PROPORTION}
        heightProportion={K_BANNER_HEIGHT_PROPORTION}
      />
      <CampaignSliderSeeAllPromoButton />
      <ReflowView
        widthProportion={375}
        heightProportion={95}
        style={s.gradient}
      >
        <Gradient
          direction="vertical"
          style={{ flex: 1 }}
          colors={['rgba(0, 0, 0, 0.4)', 'rgba(119, 119, 119, 0)']}
        />
      </ReflowView>
    </View>
  );
});

CampaignSliderMain.propTypes = {
  campaignSliderMainRef: PropTypes.shape().isRequired,
};

const s = StyleSheet.create({
  gradient: {
    ...cStyles.abs,
    ...cStyles.top,
    ...cStyles.left,
    ...cStyles.right,
    zIndex: 10,
  },
  pagination: {
    ...cStyles.ptn,
    ...cStyles.mbn,
    ...cStyles.plxl,
    height: 24,
    bottom: 0,
    pointerEvents: 'none',
  },
});

export default CampaignSliderMain;
