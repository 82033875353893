import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { APIError } from 'types';

import {
  getLoadUnseenNotificationsCountQueryKey,
  getLoadUserNotificationsQueryKey,
  loadUnseenNotificationsCount,
  loadUserNotifications,
  markAllNotificationsAsSeen,
} from './api';
import { UnseenNotificationsCountData, UserNotification } from './types';

export const useQueryLoadUserNotifications = (
  options?: UseQueryOptions<UserNotification[], APIError>,
) => {
  return useQuery(getLoadUserNotificationsQueryKey(), loadUserNotifications, {
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    retry: 1,
    ...options,
  });
};

export const useQueryLoadUnseenNotificationsCount = (
  options?: UseQueryOptions<UnseenNotificationsCountData, APIError>,
) => {
  return useQuery(
    getLoadUnseenNotificationsCountQueryKey(),
    loadUnseenNotificationsCount,
    {
      refetchInterval: 5 * 60 * 1000,
      retry: 1,
      ...options,
    },
  );
};

export const useMutationMarkAllNotificationsAsSeen = (
  options?: UseMutationOptions<UserNotification[], APIError, string>,
) => {
  return useMutation((email) => markAllNotificationsAsSeen(email), options);
};
