import React, { memo } from 'react';

import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Colors, StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

interface FillerCardProps {
  name: string;
  linkUrl: string;
  isUsingAnchor?: boolean;
}

const FillerCard: React.FC<FillerCardProps> = memo(
  ({ name, linkUrl, isUsingAnchor = false }) => {
    return (
      <ButtonWrapper
        to={linkUrl}
        linkProps={{
          isUsingAnchor,
          style: cStyles.flex1,
        }}
        hoverableViewStyle={s.container}
        style={s.buttonContainer}
      >
        {() => (
          <View style={s.contentContainer}>
            <Text theme="b3-teal500" style={s.text}>
              {name}
            </Text>
          </View>
        )}
      </ButtonWrapper>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.height100,
  },
  buttonContainer: {
    ...cStyles.height100,
    paddingBottom: 28,
  },
  contentContainer: {
    ...cStyles.br8,
    ...cStyles.height100,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    backgroundColor: Colors.C_TEAL_100,
  },
  text: {
    ...cStyles.tac,
  },
});

export default FillerCard;
