import PropTypes from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { ReflowView, StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

const noop = () => null;
const emptyObject = {};

const HeroBanner = ({
  buttonProps,
  onPress,
  style,
  imgUrl,
  to,
  width,
  height,
}) => {
  return (
    <ButtonWrapper
      to={to}
      onPress={onPress}
      hoverableViewStyle={style}
      {...buttonProps}
    >
      {() => (
        <ReflowView widthProportion={width} heightProportion={height}>
          <SmartImage
            source={imgUrl}
            sizes="100vw"
            style={s.heroImage}
            resizeMode="cover"
            additionalQuery={{ format: 'jpeg', width, height }}
            shouldPreloadSmallerVersion={false}
          />
        </ReflowView>
      )}
    </ButtonWrapper>
  );
};

const s = StyleSheet.create({
  imgContainer: {
    ...cStyles.flex1,
  },

  heroImage: {
    ...cStyles.flex1,
    ...cStyles.brs,
  },
});

HeroBanner.propTypes = {
  buttonProps: PropTypes.shape(),
  imgUrl: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  style: stylePropTypes,
};

HeroBanner.defaultProps = {
  buttonProps: emptyObject,
  width: 327,
  height: 178,
  onPress: noop,
  style: null,
};

export default React.memo(HeroBanner);
