import React, { memo, useCallback, useMemo } from 'react';

import { Colors, StyleSheet, cStyles } from '@dkrm/ui-kit-v2';

import FullWidthCard from 'app-libs/components/FullWidthCard';
import { trackClickDnbServiceType } from 'app-libs/trackers';

const K_IMAGE_PROPORTION = {
  width: 167,
  height: 128,
};

interface CategoryCardProps {
  name: string;
  imageUrl: string;
  linkUrl: string;
  isUsingAnchor?: boolean;
}

const CategoryCard: React.FC<CategoryCardProps> = memo(
  ({ name, imageUrl, linkUrl, isUsingAnchor = false }) => {
    const trackOnPress = useCallback(() => {
      trackClickDnbServiceType(linkUrl, name);
    }, [linkUrl, name]);

    const imageProps = useMemo(() => {
      return { sizes: 'auto' };
    }, []);

    const linkProps = useMemo(() => {
      return { isUsingAnchor };
    }, [isUsingAnchor]);

    return (
      <FullWidthCard
        title={name}
        onPress={trackOnPress}
        linkUrl={linkUrl}
        imageUrl={imageUrl}
        imageProps={imageProps}
        textContainerStyle={cStyles.pv0}
        titleStyle={s.categoryTitle}
        imageProportion={K_IMAGE_PROPORTION}
        linkProps={linkProps}
      />
    );
  },
);

const s = StyleSheet.create({
  categoryTitle: {
    ...cStyles.fwn,
    fontSize: 14,
    lineHeight: 20,
    color: Colors.C_NEUTRAL_900,
  },
});

export default CategoryCard;
