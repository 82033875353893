import React, { memo, useCallback } from 'react';

import { slugify } from 'app-libs/etc';

import { useQueryLoadSEOTopSearchQueries } from 'entities/seo/utils';

import SearchSuggestionInner from './SearchSuggestionInner';

// @note(dika) 14 Nov 2024: Had to use Wrapper Component here
// because SearchSuggestion is still using Class Component
// and it is quite difficult to refactor the file and
// all codes will be moved to loki-retail either way
const SearchSuggestionWrapper: React.FC<TYPEFIXME> = memo((props) => {
  const { topSearchQueries } = useQueryLoadSEOTopSearchQueries();

  const findTopSearchQueryFromQuery = useCallback(
    (searchQuery: string) => {
      if (
        topSearchQueries.find(
          (topSearchQuery) =>
            topSearchQuery.slug.toUpperCase() ===
            slugify(searchQuery).toUpperCase(),
        )
      ) {
        return searchQuery;
      }
      return null;
    },
    [topSearchQueries],
  );

  return (
    <SearchSuggestionInner
      {...props}
      findTopSearchQueryFromQuery={findTopSearchQueryFromQuery}
    />
  );
});

export default SearchSuggestionWrapper;
