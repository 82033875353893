import React, { memo, useMemo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ReflowView, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { useQueryLoadOfflineStoreLocationList } from 'entities/offlineStore/utils';

import sectionStyles from '../styles';
import BusinessUnitCard from './BusinessUnitCard';
import {
  businessUnitCategoriesBottom,
  businessUnitCategoriesTop,
} from './constants';

const BusinessUnit = memo(() => {
  const { data, isLoading, isError } = useQueryLoadOfflineStoreLocationList();

  const offlineStoreCardTitle = useMemo(() => {
    let title = 'Cek\nShowroom';
    if (!isLoading && !isError && data.length > 0) {
      const { length } = data.filter(
        (offlineStore) => offlineStore.seoAreas.length > 0,
      );
      title = `Cek ${length}\nShowroom`;
    }
    return title;
  }, [data, isError, isLoading]);

  const [furnitureCard, interiorDesignCard, propertyCard] =
    businessUnitCategoriesTop;
  const [homeAccessoriesCard, dekorumaBusinessCard, offlineStoreCard] =
    businessUnitCategoriesBottom;

  return (
    <View style={[sectionStyles.sectionContainer, s.container]}>
      <Text style={sectionStyles.sectionTitle} data-cy="business-unit-label">
        No.1 Japandi Style Furnishing
      </Text>
      <ReflowView
        widthProportion={327}
        heightProportion={64}
        style={cStyles.mtxl}
      >
        <View style={[cStyles.flexDirRow, cStyles.flex1]}>
          <BusinessUnitCard
            style={s.leftCard}
            imageStyle={s.leftCardImage}
            cardWidthProportion={160}
            cardHeightProportion={64}
            cardToScreenWidthProportion={160 / 327}
            imageSource={furnitureCard.imageSource}
            linkProps={furnitureCard.linkProps}
            backgroundColor={furnitureCard.backgroundColor}
            title={furnitureCard.title}
          />
          <BusinessUnitCard
            style={s.middleCard}
            cardWidthProportion={76}
            cardHeightProportion={64}
            cardToScreenWidthProportion={76 / 327}
            imageSource={interiorDesignCard.imageSource}
            linkProps={interiorDesignCard.linkProps}
            backgroundColor={interiorDesignCard.backgroundColor}
            title={interiorDesignCard.title}
          />
          <BusinessUnitCard
            style={s.rightCard}
            cardWidthProportion={76}
            cardHeightProportion={64}
            cardToScreenWidthProportion={76 / 327}
            imageSource={propertyCard.imageSource}
            linkProps={propertyCard.linkProps}
            backgroundColor={propertyCard.backgroundColor}
            title={propertyCard.title}
            additionalBadge={propertyCard.additionalBadge}
          />
        </View>
      </ReflowView>
      <ReflowView
        widthProportion={327}
        heightProportion={64}
        style={cStyles.mtm}
      >
        <View style={[cStyles.flexDirRow, cStyles.flex1]}>
          <BusinessUnitCard
            style={s.leftCard}
            imageStyle={s.leftCardImage}
            cardWidthProportion={160}
            cardHeightProportion={64}
            cardToScreenWidthProportion={160 / 327}
            imageSource={homeAccessoriesCard.imageSource}
            linkProps={homeAccessoriesCard.linkProps}
            backgroundColor={homeAccessoriesCard.backgroundColor}
            title={homeAccessoriesCard.title}
          />
          <BusinessUnitCard
            style={s.middleCard}
            cardWidthProportion={76}
            cardHeightProportion={64}
            cardToScreenWidthProportion={76 / 327}
            imageSource={dekorumaBusinessCard.imageSource}
            linkProps={dekorumaBusinessCard.linkProps}
            backgroundColor={dekorumaBusinessCard.backgroundColor}
            title={dekorumaBusinessCard.title}
          />
          <BusinessUnitCard
            style={s.rightCard}
            cardWidthProportion={76}
            cardHeightProportion={64}
            cardToScreenWidthProportion={76 / 327}
            imageSource={offlineStoreCard.imageSource}
            linkProps={offlineStoreCard.linkProps}
            backgroundColor={offlineStoreCard.backgroundColor}
            title={offlineStoreCardTitle}
          />
        </View>
      </ReflowView>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.mtn,
    ...cStyles.ptxxl,
  },
  leftCardImage: {
    right: 4,
  },
  leftCard: {
    ...cStyles.mrs,
    ...cStyles.flex3,
  },
  middleCard: {
    ...cStyles.mls,
    ...cStyles.mrxs,
    ...cStyles.flex3,
  },
  rightCard: {
    marginLeft: 6,
    ...cStyles.flex2,
  },
});

export default BusinessUnit;
