export const K_MICROSITE_SECTION_TYPE_BANNER_SLIDER = 'Banner Slider';
export const K_MICROSITE_SECTION_TYPE_CATEGORY_MARKETPLACE =
  'Category Marketplace';
export const K_MICROSITE_SECTION_TYPE_FLASH_SALE = 'Flash Sale';
export const K_MICROSITE_SECTION_TYPE_HOTSPOT = 'Hotspot';
export const K_MICROSITE_SECTION_TYPE_MOODBOARD = 'Moodboard';
export const K_MICROSITE_SECTION_TYPE_OFFER = 'Offer';
export const K_MICROSITE_SECTION_TYPE_PROJECT_LEAD_FORM = 'Project Lead Form';
export const K_MICROSITE_SECTION_TYPE_TEXT = 'Text';
export const K_MICROSITE_SECTION_TYPE_VOUCHER_SLIDER = 'Voucher Slider';

export const K_MAX_MOODBOARD_PRODUCTS_TO_LOAD = 24;
