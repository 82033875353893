import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.C_BLACK_5,
    height: 250,
  },
  quoteContainer: {
    ...cStyles.flex1,
    ...cStyles.phxxl,
    ...cStyles.flexMiddle,
    paddingTop: 20,
    bottom: 20,
    textAlign: 'center',
  },
  quoteText: {
    ...cStyles.ptm,
    fontSize: 18,
    lineHeight: 24,
    color: colors.C_BLACK_65,
  },
  quoteSliderContainer: {
    ...cStyles.flex1,
    backgroundColor: colors.C_BLACK_5,
  },
  pagination: {
    ...cStyles.abs,
    ...cStyles.ptn,
    ...cStyles.mbn,
    height: 24,
    bottom: 16,
    pointerEvents: 'none',
  },
  paginationDot: {
    ...cStyles.maxs,
  },
});
