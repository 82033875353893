import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Rectangle, View } from '@dkrm/ui-kit-basic';

const Skeleton: React.FC = memo(() => {
  return (
    <View style={cStyles.flex1}>
      <Rectangle height={200} />
    </View>
  );
});

export default Skeleton;
