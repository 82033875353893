import React from 'react';
import { connect } from 'react-redux';

import { View, Text, Swiper } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import sectionStyles from '../styles';
import styles from './styles';
import { K_MEDIA_REVIEWS } from './constants';

const K_QUOTE_BLOCK_WIDTH_PROPORTION = 375;
const K_QUOTE_BLOCK_HEIGHT_PROPORTION = 250;
const K_LOGO_WIDTH = 130;
const K_LOGO_HEIGHT = 35;

const K_DEFAULT_SCREEN_WIDTH = 375;

const K_IMAGE_ADDITIONAL_QUERY = {
  fit: 'bounds',
  auto: 'webp',
  optimize: 'high',
};

@connect((state) => ({
  pageWidth: state.dimension.width || K_DEFAULT_SCREEN_WIDTH,
}))
export default class MediaReview extends React.PureComponent {
  keyExtractor = (item) => item.title;

  renderQuote = ({ logoUrl, title, textStyle }) => (
    <View style={styles.quoteContainer}>
      <SmartImage
        source={logoUrl}
        width={K_LOGO_WIDTH}
        height={K_LOGO_HEIGHT}
        additionalQuery={K_IMAGE_ADDITIONAL_QUERY}
        resizeMode="contain"
        accessibilityLabel={title}
      />
      <Text theme="body1" style={[styles.quoteText, textStyle]}>
        &quot;{title}&quot;
      </Text>
    </View>
  );

  render = () => (
    <View style={[sectionStyles.sectionContainer, styles.container]}>
      <Swiper
        data={K_MEDIA_REVIEWS}
        renderItem={this.renderQuote}
        showSideNavigationButton={{
          showLeftSideNavigation: false,
          showRightSideNavigation: false,
        }}
        paginationIndicatorPosition="center"
        paginationTheme="tosca"
        paginationDotSize={6}
        paginationDotStyle={styles.paginationDot}
        paginationStyle={styles.pagination}
        containerStyle={styles.quoteSliderContainer}
        reflowViewProps={{ style: { ...cStyles.ptn, height: 250 } }}
        widthProportion={K_QUOTE_BLOCK_WIDTH_PROPORTION}
        heightProportion={K_QUOTE_BLOCK_HEIGHT_PROPORTION}
      />
    </View>
  );
}
