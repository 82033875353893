import config from 'config';

import { K_ROUTE_ROOMS } from 'constants/routeConstants';

import { ShopByRoomItem } from './type';

export const K_SHOP_BY_ROOM_ITEMS: ShopByRoomItem[] = [
  {
    title: 'Ruang Keluarga & Tamu',
    linkUrl: `${K_ROUTE_ROOMS}/Ruang-Keluarga`,
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/room/v3/card/RuangKeluarga.png?auto=webp`,
  },
  {
    title: 'Ruang Makan & Dapur',
    linkUrl: `${K_ROUTE_ROOMS}/Dapur`,
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/room/v3/card/Dapur.png?auto=webp`,
  },
  {
    title: 'Kamar Tidur',
    linkUrl: `${K_ROUTE_ROOMS}/Kamar-Tidur`,
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/room/v3/card/KamarTidur.png?auto=webp`,
  },
  {
    title: 'Ruang Kerja & Kantor',
    linkUrl: `${K_ROUTE_ROOMS}/Ruang-Kerja`,
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/room/v3/card/RuangKerja.png?auto=webp`,
  },
];
