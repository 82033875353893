import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet } from '@dkrm/ui-kit-basic';

import { useQueries } from 'app-libs/hook_modules/router';

import { K_POSITION_BOTTOM_CENTER } from 'app-libs/components/Notification/BasePopup/constants';
import { showSnackBar } from 'app-libs/redux_modules/flow_modules/notification';

export const useEmailValidationEffect = () => {
  const query = useQueries();
  const { validateEmailSuccess } = query;
  const dispatch = useDispatch();
  useEffect(() => {
    if (validateEmailSuccess === undefined) return;
    if (validateEmailSuccess === 'true') {
      dispatch(
        showSnackBar({
          message: 'Email berhasil diverifikasi',
          position: K_POSITION_BOTTOM_CENTER,
          duration: 2000,
        }),
      );
    } else {
      dispatch(
        showSnackBar({
          message: 'Email gagal diverifikasi',
          position: K_POSITION_BOTTOM_CENTER,
          snackBarStyle: s.snackBarError,
          textStyle: s.snackBarErrorText,
          duration: 2000,
        }),
      );
    }
  }, [validateEmailSuccess, dispatch]);
};

const s = StyleSheet.create({
  snackBarError: {
    backgroundColor: '#fdeceb',
  },

  snackBarErrorText: {
    color: '#ef4237',
  },
});
