import React, { memo } from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ReflowView, StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Image } from '@dkrm/ui-kit-basic/v3';

import { K_ROUTE_FURNITURE_HOMEPAGE } from 'constants/routeConstants';

const K_JAPANDI_BANNER_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/homepage/banner/japandi-mobile.png`;
const K_JAPANDI_PROMOTION_URL = `${K_ROUTE_FURNITURE_HOMEPAGE}-Japandi`;

const JapandiSection: React.FC = memo(() => {
  return (
    <Button style={cStyles.mtxs} to={K_JAPANDI_PROMOTION_URL}>
      {() => (
        <ReflowView widthProportion={3} heightProportion={1}>
          <Image
            sizes="100vw"
            style={s.image}
            source={K_JAPANDI_BANNER_IMAGE_URL}
          />
        </ReflowView>
      )}
    </Button>
  );
});

const s = StyleSheet.create({
  image: cStyles.flex1,
});

export default JapandiSection;
