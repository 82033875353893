import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  reviewModal: {
    margin: 0,
    justifyContent: 'flex-end',
  },
  reviewModalContainer: {
    ...cStyles.ptxl,
    ...cStyles.pbxxl,
    paddingHorizontal: 20,
  },
  images: {
    width: 66,
    height: 66,
  },
  descriptionContainer: {
    ...cStyles.flexDirCol,
    ...cStyles.flex1,
    ...cStyles.mlm,
  },
});
