import { useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { DjangoPaginatedResponse } from 'types';

import {
  getLoadBrandRatingKey,
  getLoadBrandReviewKey,
  getLoadProductRatingKey,
  getLoadProductReviewKey,
  loadBrandRating,
  loadBrandReview,
  loadProductRating,
  loadProductReview,
} from './api';
import { ProductReview } from './types';

export const useQueryLoadProductRating = (
  upc?: string,
  siblingUpcs: string[] = [],
) => {
  return useQuery(
    getLoadProductRatingKey(upc, siblingUpcs),
    () => loadProductRating(upc, siblingUpcs),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: !!upc,
    },
  );
};

export const useQueryLoadBrandRating = (brand: string) => {
  return useQuery(getLoadBrandRatingKey(brand), () => loadBrandRating(brand), {
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: !!brand,
  });
};

export const useInfiniteQueryLoadProductReview = (
  upc: string,
  siblingUpcs: string[],
) => {
  const { fetchNextPage, hasNextPage, isLoading, isError, error, data } =
    useInfiniteQuery({
      queryKey: getLoadProductReviewKey(upc, siblingUpcs),
      queryFn: ({ pageParam = 0 }) =>
        loadProductReview(upc, siblingUpcs, pageParam + 1),
      getNextPageParam: (lastPage: DjangoPaginatedResponse<ProductReview>) => {
        if (lastPage.next) {
          return lastPage.page + 1;
        }
        return undefined;
      },
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: !!upc,
    });

  const productReviews = useMemo(
    () =>
      data?.pages?.reduce(
        (
          res: ProductReview[],
          page: DjangoPaginatedResponse<ProductReview>,
        ) => [...res, ...page.results],
        [],
      ) || [],
    [data],
  );
  const reviewCount = data?.pages?.[0]?.count || 0;
  return {
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    productReviews,
    reviewCount,
  };
};

export const useInfiniteQueryLoadBrandReview = (
  brand: string,
  score: number,
) => {
  const { fetchNextPage, hasNextPage, isLoading, isError, error, data } =
    useInfiniteQuery({
      queryKey: getLoadBrandReviewKey(brand, score),
      queryFn: ({ pageParam = 1 }) => loadBrandReview(brand, score, pageParam),
      getNextPageParam: (lastPage: DjangoPaginatedResponse<ProductReview>) =>
        lastPage.next && lastPage.page + 2,
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: !!brand,
    });

  const brandReviews = useMemo(
    () =>
      data?.pages?.reduce(
        (
          res: ProductReview[],
          page: DjangoPaginatedResponse<ProductReview>,
        ) => [...res, ...page.results],
        [],
      ) || [],
    [data],
  );
  return {
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    brandReviews,
  };
};
