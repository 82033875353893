import {
  getBreadcrumbFromIncompleteParams,
  replaceSpecialCharacter,
} from 'app-libs/etc/categoryHelper';

import { K_MATTRESS_BRANDS } from 'constants/mattressConstants';

const K_UPC_REGEX = /^[A-z0-9]+--?[0-9]+$/g;

/**
 * Handle User search for category, brand, material, etc
 */
export function generateBreadcrumbFromQuery(query, allCategoryBreadcrumbs) {
  const breadcrumbs = getBreadcrumbFromIncompleteParams(
    { lvl0: query },
    allCategoryBreadcrumbs,
    true,
  );
  return breadcrumbs;
}

export function findBrandFromQuery(searchQuery, brands) {
  return brands.find((brand) => {
    const cleanBrand = replaceSpecialCharacter(brand, '').toLowerCase(); // prettier-ignore
    const cleanQuery = replaceSpecialCharacter(searchQuery, '').toLowerCase(); // prettier-ignore
    return cleanBrand === cleanQuery;
  });
}

export function findUpcFromQuery(searchQuery) {
  if (searchQuery.match(K_UPC_REGEX)) {
    return searchQuery.toUpperCase();
  }
  return null;
}

export function findMattressBrandFromQuery(searchQuery) {
  return K_MATTRESS_BRANDS.find((brand) => {
    const cleanBrand = replaceSpecialCharacter(brand, '').toLowerCase(); // prettier-ignore
    const cleanQuery = replaceSpecialCharacter(searchQuery, '').toLowerCase(); // prettier-ignore
    return cleanBrand === cleanQuery;
  });
}
