import { UseQueryOptions, useQuery } from 'react-query';
import { APIError } from 'types';

import { getMemberLoyalty, getMemberLoyaltyQueryKey } from './api';
import { MemberLoyalty } from './types';

const K_ONE_MINUTE_IN_MILLISECONDS = 60000;

export const useQueryLoadMemberLoyalty = (
  userUuid: string,
  options?: UseQueryOptions<MemberLoyalty, APIError, MemberLoyalty, string[]>,
) => {
  return useQuery(getMemberLoyaltyQueryKey(userUuid), getMemberLoyalty, {
    staleTime: 30 * K_ONE_MINUTE_IN_MILLISECONDS,
    cacheTime: 60 * K_ONE_MINUTE_IN_MILLISECONDS,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
