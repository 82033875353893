import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import {
  ButtonWrapper,
  Colors,
  Image,
  StyleSheet,
  Text,
  View,
  cStyles,
} from '@dkrm/ui-kit-v2';

import { formatPriceId } from 'app-libs/etc';
import { getUrlWithQuery } from 'app-libs/etc/routeHelper';
import { useHistory } from 'app-libs/hook_modules/router';
import { getNewCustomerVouchers } from 'app-libs/redux_modules/entity_modules/selectors/voucher';
import { loadCustomerVouchers } from 'app-libs/redux_modules/entity_modules/voucher';
import * as authSelectors from 'app-libs/redux_modules/selectors/auth';

import { K_ROUTE_SIGNUP } from 'constants/routeConstants';

import {
  K_NEW_VOUCHER_RED_LOGO_SIZE,
  K_NEW_VOUCHER_RED_LOGO_URL,
  K_UI_KIT_V2_NEW_COLOR_RED_500,
} from './constants';

const NewCustomerVoucherSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const newCustomerVouchers = useSelector(getNewCustomerVouchers);

  useEffect(() => {
    if (isEmpty(newCustomerVouchers)) {
      dispatch(loadCustomerVouchers());
    }
  }, []); // eslint-disable-line
  const firstNewCustomerVoucher = get(newCustomerVouchers, '[0]', {});
  const { discountValue, code } = firstNewCustomerVoucher;

  const handleClaimVoucher = useCallback(() => {
    const url = getUrlWithQuery(K_ROUTE_SIGNUP, {
      claimVoucherExtraQuery: 'homepage',
      claimVoucherCode: code,
    });
    actionRouteActionOpenURL(url, history);
  }, [code, history]);

  if (isLoggedIn) return null;
  if (isEmpty(firstNewCustomerVoucher)) return null;

  return (
    <View style={s.container}>
      <View style={s.descriptionContainer}>
        <Image
          source={K_NEW_VOUCHER_RED_LOGO_URL}
          width={K_NEW_VOUCHER_RED_LOGO_SIZE}
          height={K_NEW_VOUCHER_RED_LOGO_SIZE}
        />
        <Text theme="b4-red500" style={s.description}>
          Klaim Voucher Belanja <b>Rp{formatPriceId(discountValue)}</b> Khusus
          Pengguna Baru!
        </Text>
      </View>
      <ButtonWrapper style={s.claimButton} onPress={handleClaimVoucher}>
        <Text theme="b4-red500" style={s.claimButtonText}>
          Daftar
        </Text>
      </ButtonWrapper>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.br8,
    ...cStyles.mt16,
    ...cStyles.mh16,
    ...cStyles.pa8,
    ...cStyles.pt12,
    ...cStyles.pr12,
    ...cStyles.flexDirRow,
    ...cStyles.shadowOverlay,
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    backgroundImage: `linear-gradient(to bottom right, white, ${Colors.C_RED_100})`,
  },
  descriptionContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.pl4,
    alignItems: 'center',
    maxWidth: '80%',
  },
  description: {
    ...cStyles.mh12,
    color: K_UI_KIT_V2_NEW_COLOR_RED_500,
  },
  claimButton: {
    ...cStyles.pv8,
    ...cStyles.ph16,
    ...cStyles.br8,
    ...cStyles.bwam,
    minWidth: 'fit-content',
    borderColor: K_UI_KIT_V2_NEW_COLOR_RED_500,
  },
  claimButtonText: {
    color: K_UI_KIT_V2_NEW_COLOR_RED_500,
  },
});

export default NewCustomerVoucherSection;
