import PropTypes from 'prop-types';
import React from 'react';

import { Text, View } from '@dkrm/ui-kit-basic';

import styles from './styles';

const SectionHeader = ({ icon, title, subtitle }) => {
  const { fill: iconFill, IconComponent, size = 16 } = icon;

  return (
    <>
      <View style={styles.titleContainer}>
        {IconComponent && (
          <IconComponent style={styles.icon} fill={iconFill} size={size} />
        )}
        <Text theme="h4-black">{title}</Text>
      </View>
      {subtitle && (
        <Text theme="body4-black60" style={styles.subtitleContainer}>
          {subtitle}
        </Text>
      )}
    </>
  );
};

SectionHeader.propTypes = {
  icon: PropTypes.shape({
    IconComponent: PropTypes.shape().isRequired,
    fill: PropTypes.string.isRequired,
    size: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

SectionHeader.defaultProps = {
  icon: {
    IconComponent: null,
    fill: '',
  },
  subtitle: '',
};

export default React.memo(SectionHeader);
