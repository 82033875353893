import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { BellOutline } from '@dkrm/icons';

import { User } from 'app-libs/redux_modules/auth/types';
import { getAuthUser } from 'app-libs/redux_modules/selectors/auth';

import { useQueryLoadUnseenNotificationsCount } from 'entities/notification/utils';

import { ViewStyle } from 'types/styles';

interface NotificationButtonProps {
  style?: ViewStyle | ViewStyle[];
}

const NotificationButton: React.FC<NotificationButtonProps> = memo(
  ({ style }) => {
    const user: User = useSelector(getAuthUser);

    const email = user?.email ?? '';

    const { data } = useQueryLoadUnseenNotificationsCount({ enabled: !!email });

    const unseenNotificationCount = data?.unseen ?? 0;

    return (
      <BellOutline
        showBadge={unseenNotificationCount > 0}
        badgeText={unseenNotificationCount}
        badgeSize={13}
        style={style}
        size={24}
        theme="black"
      />
    );
  },
);

export default NotificationButton;
