import React from 'react';
import PropTypes from 'prop-types';

import { View, ScrollView, Text, HorizontalDivider } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import {
  K_ROUTE_POST_SEARCH,
  K_ROUTE_REAL_ESTATE_LIST,
} from 'constants/routeConstants';

import styles from './styles';

export default class SuggestionList extends React.PureComponent {
  static propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.object),
    selectedSuggestionIdx: PropTypes.number,
    style: stylePropTypes,
    onPress: PropTypes.func,
    baseURL: PropTypes.string,
  };

  static defaultProps = {
    suggestions: [],
    selectedSuggestionIdx: 0,
    style: null,
    onPress: () => null,
    baseURL: '',
  };

  renderSuggestionText(highlightedQuery, words) {
    const splittedQ = highlightedQuery
      .split(/<\/?em>/g)
      .filter((s) => s !== '');
    const texts = splittedQ.map((str) => {
      let content = str;
      if (words.some((word) => word.toLowerCase() === str.toLowerCase())) {
        content = <Text style={styles.highlighted}>{str}</Text>;
      }
      return content;
    });
    return texts;
  }

  renderSuggestionUI = (key, query, title, idx = null) => {
    const { onPress, selectedSuggestionIdx } = this.props;
    return (
      <SuggestionListItem
        onPress={onPress}
        buttonProps={{
          key,
          query,
          title,
          theme: 'black',
          themeType: 'tertiary',
          small: true,
          isSelected: idx === selectedSuggestionIdx - 1,
        }}
      />
    );
  };

  renderSuggestionsForPost = (suggestion, idx) => {
    const { post_title: title } = suggestion;
    return this.renderSuggestionUI(suggestion.objectID, title, title, idx);
  };

  renderSuggestionsForRealEstate = (suggestion) => {
    const highlightedSuggestion = suggestion._highlightResult
      ? this.renderSuggestionText(
          suggestion._highlightResult.title.value,
          suggestion._highlightResult.title.matchedWords,
        )
      : suggestion.title;
    return this.renderSuggestionUI(
      suggestion.objectID,
      suggestion.title,
      highlightedSuggestion,
    );
  };

  renderSuggestionsForProduct = (suggestion, idx) => {
    const highlightedSuggestion = suggestion._highlightResult
      ? this.renderSuggestionText(
          suggestion._highlightResult.query.value,
          suggestion._highlightResult.query.matchedWords,
        )
      : suggestion.query;
    return this.renderSuggestionUI(
      suggestion.objectID,
      suggestion.query,
      highlightedSuggestion,
      idx,
    );
  };

  renderSuggestionItem = (suggestion, idx) => {
    const { baseURL } = this.props;
    if (baseURL.startsWith(K_ROUTE_POST_SEARCH)) {
      return this.renderSuggestionsForPost(suggestion, idx);
    }
    if (baseURL.startsWith(K_ROUTE_REAL_ESTATE_LIST)) {
      return this.renderSuggestionsForRealEstate(suggestion);
    }
    return this.renderSuggestionsForProduct(suggestion, idx);
  };

  render() {
    const { suggestions, style } = this.props;
    return (
      <ScrollView style={[cStyles.bgw, style]}>
        {suggestions.map((suggestion, idx) =>
          this.renderSuggestionItem(suggestion, idx),
        )}
      </ScrollView>
    );
  }
}

export class SuggestionListItem extends React.PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    buttonProps: PropTypes.shape(),
    isSelected: PropTypes.bool,
  };

  static defaultProps = {
    onPress: () => null,
    buttonProps: {},
    isSelected: false,
  };

  state = {
    hover: false,
  };

  render() {
    const { buttonProps, onPress } = this.props;
    const { key, query, isSelected, ...rest } = buttonProps;
    const { hover } = this.state;
    return (
      <View key={key}>
        <ButtonWithText
          onPress={() => onPress(query)}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          {...rest}
          buttonStyle={[
            styles.suggestionTextContainer,
            {
              backgroundColor:
                hover || isSelected ? colors.C_BLACK_20 : colors.C_WHITE_100,
            },
          ]}
        />
        <HorizontalDivider style={cStyles.mhxxl} />
      </View>
    );
  }
}
