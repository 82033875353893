import config from 'config';

import { BrandItem } from './type';

export const K_EXCLUSIVE_BRAND_DATA: BrandItem[] = [
  {
    brand: 'Tenzo Living',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/brands/Tenzo-Living.jpg`,
    subtitle: 'Premium Modern Furniture',
    title: 'Tenzo Living',
  },
  {
    brand: 'Heim Studio',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/brands/Heim-Studio.png`,
    subtitle: 'Furnitur Bergaya Japandi',
    title: 'Heim Studio',
  },
  {
    brand: 'Danari',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/brands/Danari.png`,
    subtitle: 'Aksesoris Rumah Estetik',
    title: 'Dänari',
  },
  {
    brand: 'Linori',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/brands/Linori.png`,
    subtitle: 'Koleksi Produk Tekstil',
    title: 'Linöri',
  },
  {
    brand: 'Sleep Care',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/brands/Sleepcare.png`,
    subtitle: 'Pilihan Kasur Terbaik',
    title: 'Sleep Care',
  },
];
