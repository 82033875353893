import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { DeferredContent, Rectangle, StyleSheet } from '@dkrm/ui-kit-basic';

import Hotspot from 'app-libs/components/Hotspot/Loadable';
import * as hotspotSelectors from 'app-libs/redux_modules/entity_modules/selectors/hotspot';
import { loadHotspot } from 'app-libs/redux_modules/flow_modules/browse';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';

const K_HOTSPOT_UUID = '89a5fbf6-4298-43a9-91df-98a65e7d65c1';

const DEC = React.memo(() => {
  const dispatch = useDispatch();
  const hotspot = useSelector((state) =>
    hotspotSelectors.getHotspotByHotspotId(state, K_HOTSPOT_UUID),
  );

  useEffect(() => {
    dispatch(dontShowPromptOnError(loadHotspot(K_HOTSPOT_UUID)));
  }, [dispatch]);

  if (isEmpty(hotspot)) {
    return (
      <DeferredContent
        animation="shine"
        isLoading
        renderSkeleton={() => <Rectangle height={330} style={s.decItem} />}
      />
    );
  }

  return <Hotspot hotspot={hotspot} containerStyle={{ marginTop: 32 }} />;
});

const s = StyleSheet.create({
  decItem: {
    ...cStyles.mhl,
    ...cStyles.mvs,
    width: 'auto',
    marginTop: 32,
  },
});

export default DEC;
