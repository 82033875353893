import { Product } from 'app-libs/components/Product/types';
import { getIsOutOfStockByRoutingZoneCode } from 'app-libs/etc/productHelperV2';

import { K_MARKETPLACE_PRODUCT } from 'constants/productConstants';

const K_MAX_MOODBOARD_PRODUCTS_PREVIEW = 24;

export const getShownProducts = (
  products: Product[],
  routingZoneCode: string,
) => {
  return products
    .filter(
      (product) =>
        !getIsOutOfStockByRoutingZoneCode(product, routingZoneCode) &&
        product.catalogueGroupNames.includes(K_MARKETPLACE_PRODUCT),
    )
    .slice(0, K_MAX_MOODBOARD_PRODUCTS_PREVIEW);
};
