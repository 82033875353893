import superagent from 'superagent';

import config from 'config';

import { MemberLoyalty } from './types';

export const getMemberLoyaltyQueryKey = (userUuid: string) => [
  'member-loyalty',
  userUuid,
];

export const getMemberLoyalty = async (): Promise<MemberLoyalty> => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/ulp/member-loyalty/`)
    .withCredentials();
  return res.body;
};
