import React, { memo } from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { Image } from '@dkrm/ui-kit-basic/v3';

import { ViewStyle } from 'types/styles';

interface BrandLogoContainerProps {
  brand: string;
  containerStyle?: ViewStyle;
}

const K_LOGO_SIZE = 48;

const BrandLogoContainer: React.FC<BrandLogoContainerProps> = memo(
  ({ brand, containerStyle = null }) => {
    const logoImageUrl = `${config.API_URL_MEDIA_CDN}/facets/brand/${brand}.png`;

    return (
      <View style={[s.logoContainer, containerStyle]}>
        <Image
          source={logoImageUrl}
          width={38}
          height={16}
          style={s.logoImage}
          resizeMode="contain"
        />
      </View>
    );
  },
);

const s = StyleSheet.create({
  logoImage: {
    margin: 'auto',
  },
  logoContainer: {
    ...cStyles.bwam,
    ...cStyles.bgw,
    width: K_LOGO_SIZE,
    height: K_LOGO_SIZE,
    borderRadius: K_LOGO_SIZE,
    borderColor: Colors.C_NEUTRAL_30,
  },
});

export default BrandLogoContainer;
