import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, ReflowView, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { ViewStyle } from 'types/styles';

import { ShopByRoomItem } from '../type';

interface CardProps {
  roomItem: ShopByRoomItem;
  style?: ViewStyle;
}

const K_WIDTH_PROPORTION = 1;
const K_HEIGHT_PROPORTION = 1;
const K_BADGE_WIDTH = 30;
const K_BADGE_HEIGHT = 30;
const K_CARD_SIZE = 200;

const Card: React.FC<CardProps> = memo(({ roomItem, style = null }) => {
  const { title, imageUrl, linkUrl, additionalBadgeUrl } = roomItem;

  return (
    <View style={[s.container, style]}>
      <ButtonWrapper
        to={linkUrl}
        linkProps={{ style: { flex: 1 } }}
        hoverableViewStyle={s.flex1}
        style={s.flex1}
      >
        {() => (
          <>
            <ReflowView
              widthProportion={K_WIDTH_PROPORTION}
              heightProportion={K_HEIGHT_PROPORTION}
              style={s.imageContainer}
            >
              <SmartImage
                source={imageUrl}
                style={s.image}
                resizeMode="cover"
                additionalQuery={{
                  format: 'jpeg',
                  width: K_CARD_SIZE,
                  height: K_CARD_SIZE,
                }}
              />
              {!!additionalBadgeUrl && (
                <View style={s.badgeContainer}>
                  <SmartImage
                    source={additionalBadgeUrl}
                    width={K_BADGE_WIDTH}
                    height={K_BADGE_HEIGHT}
                  />
                </View>
              )}
            </ReflowView>
            <View style={s.textContainer}>
              <Text theme="h5" style={s.title}>
                {title}
              </Text>
            </View>
          </>
        )}
      </ButtonWrapper>
    </View>
  );
});

const s = StyleSheet.create({
  flex1: cStyles.flex1,
  container: {
    ...cStyles.flex1,
    ...cStyles.mvm,
    ...cStyles.mhm,
    ...cStyles.brs,
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.02), 0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  imageContainer: { display: 'flex' },
  image: {
    ...cStyles.flex1,
    backgroundColor: Colors.C_BLACK_10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  textContainer: {
    ...cStyles.flex1,
    ...cStyles.bgw,
    ...cStyles.pam,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  title: {
    fontSize: 12,
  },
  badgeContainer: {
    ...cStyles.abs,
    top: 10,
    right: 10,
  },
});

export default Card;
