import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  titleContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    alignItems: 'center',
  },

  icon: {
    ...cStyles.mrs,
  },

  subtitleContainer: {
    ...cStyles.mts,
  },
});
