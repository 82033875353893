import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.C_SECONDARY_TOSCA_1,
    overflow: 'hidden',
    ...cStyles.mtxxl,
    ...cStyles.mhxxl,
    ...cStyles.brs,
    ...cStyles.shadowm,
  },
  buttonText: {
    ...tStyles.h6,
  },
  reviewButton: {
    ...cStyles.phxl,
    ...cStyles.pvs,
    ...cStyles.flexMiddle,
    backgroundColor: colors.C_PRIMARY_TOSCA,
    borderRadius: 4,
    width: 60,
  },
  closeButtonContainer: {
    backgroundColor: 'rgba(0, 129, 136, 0.2)',
    width: 26,
    height: 26,
    borderBottomLeftRadius: 26,
    top: 0,
    right: 0,
    overflow: 'hidden',
    ...cStyles.abs,
  },
  bannerContentContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mhxl,
    ...cStyles.mvl,
    ...cStyles.flex1,
  },
  headlineText: {
    ...cStyles.mbxs,
    fontSize: 14,
  },
});
