import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  cancelButtonContainer: {
    ...cStyles.mvl,
    ...cStyles.mhs,
  },

  searchContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.bgw,
  },
});
