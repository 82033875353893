import superagent from 'superagent';
import config from 'config';
import { DjangoPaginatedResponse } from 'types';
import {
  LoadProductRatingResponse,
  LoadBrandRatingResponse,
  ProductReview,
} from './types';

export const getLoadProductRatingKey = (
  upc: string | undefined,
  siblingUpcs: string[],
) => ['product-rating', { upc, siblingUpcs }];

export const getLoadBrandRatingKey = (brand: string) => [
  'brand-rating',
  { brand },
];

export const getLoadProductReviewKey = (
  upc: string,
  siblingUpcs: string[] = [],
) => ['product-review', { upc, siblingUpcs }];

export const getLoadBrandReviewKey = (brand: string, score: number) => [
  'brand-review',
  { brand, score },
];

export const loadProductRating = async (
  upc: string | undefined,
  siblingUpcs: string[],
): Promise<LoadProductRatingResponse> => {
  const siblingUpcsQueryParams = siblingUpcs
    .map((siblingUpc) => `siblingUpcs=${siblingUpc}`)
    .join('&');
  const res = await superagent.get(
    `${config.API_URL_SIN}/products/${upc}/ratings/?${siblingUpcsQueryParams}`,
  );
  return res.body;
};

export const loadBrandRating = async (
  brand: string,
): Promise<LoadBrandRatingResponse> => {
  const res = await superagent.get(
    `${config.API_URL_SIN}/brands/${brand}/ratings/`,
  );
  return res.body;
};

export const loadProductReview = async (
  upc: string,
  siblingUpcs: string[],
  page: number,
): Promise<DjangoPaginatedResponse<ProductReview>> => {
  const siblingUpcsQueryParams = siblingUpcs
    .map((siblingUpc) => `siblingUpcs=${siblingUpc}`)
    .join('&');
  const res = await superagent
    .get(
      `${config.API_URL_SIN}/products/${upc}/reviews/?${siblingUpcsQueryParams}`,
    )
    .query({
      page,
    });
  return res.body;
};

export const loadBrandReview = async (
  brand: string,
  score: number | null,
  page: number,
): Promise<DjangoPaginatedResponse<ProductReview>> => {
  const res = await superagent
    .get(`${config.API_URL_SIN}/brands/${brand}/reviews/`)
    .query({
      page,
      score,
    });
  return res.body;
};
