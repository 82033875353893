import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import withDrawerConsumer from '@dkrm/general-libs/Context/DrawerContext/withDrawerConsumer';
import cStyles from '@dkrm/general-libs/theme/styles';
import { CartOutline, Menu, WhatsappOutline } from '@dkrm/icons';
import { Colors } from '@dkrm/ui-kit-basic';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';

import NavbarWithTitle from 'app-libs/components/NavbarImplementation/NavbarWithTitle';
import { useHistory } from 'app-libs/hook_modules/router';
import { headBasketAndLoadIfExist } from 'app-libs/redux_modules/entity_modules/basket';
import {
  getBasketAsyncState,
  getTotalQuantityOfBasket,
} from 'app-libs/redux_modules/entity_modules/selectors/basket';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { trackClickedNavbarChat } from 'app-libs/trackers';

import { K_DEKORUMA_LOGO_WHITE } from 'constants/assetConstants';
import { K_DEKORUMA_OFFICIAL_WHATSAPP_URL } from 'constants/productConstants';
import { K_ROUTE_CART, K_ROUTE_NOTIFICATION } from 'constants/routeConstants';

import NotificationButton from './NotificationButton';
import SearchBar from './SearchBar';

const MainHomeNavbar = memo(({ drawerRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const quantityOfBasket = useSelector(getTotalQuantityOfBasket);
  const { loaded: isBasketLoaded } = useSelector(getBasketAsyncState);

  const actionOpenWA = useCallback(() => {
    const message =
      'Halo Dekoruma, saya ingin konsultasi terkait furniture idaman!';
    const whatsAppLink = `${K_DEKORUMA_OFFICIAL_WHATSAPP_URL}?text=${message}`;

    trackClickedNavbarChat();
    actionRouteActionOpenURL(whatsAppLink, history, { isUsingAnchor: true });
  }, [history]);

  const navbarRightButtons = [
    {
      IconComponent: NotificationButton,
      url: K_ROUTE_NOTIFICATION,
      props: { isUsingAnchor: false, style: { paddingHorizontal: 6 } },
    },
    {
      IconComponent: WhatsappOutline,
      props: { style: { paddingHorizontal: 6 } },
      onPress: actionOpenWA,
    },
    {
      IconComponent: CartOutline,
      url: K_ROUTE_CART,
      showBadge: !!quantityOfBasket,
      props: { isUsingAnchor: true, style: { paddingLeft: 6 } },
      iconProps: { badgeText: quantityOfBasket, badgeSize: 13 },
    },
  ];

  useEffect(() => {
    if (!isBasketLoaded) {
      dispatch(dontShowPromptOnError(headBasketAndLoadIfExist()));
    }
  }, [dispatch, isBasketLoaded]);

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.openDrawer();
    }
  };

  return (
    <View style={s.containerWhite}>
      <NavbarWithTitle
        containerStyle={s.navbarContainer}
        logoImageUrl={K_DEKORUMA_LOGO_WHITE}
        iconSize={24}
        theme="black80"
        rightButtons={navbarRightButtons}
        leftButtonIcon={Menu}
        leftButtonProps={{
          to: undefined,
          onPress: openDrawer,
        }}
        shouldForceBackButtonWebView={false}
        title={<SearchBar searchBoxStyle={s.searchBoxGrey} />}
      />
    </View>
  );
});

MainHomeNavbar.propTypes = {
  drawerRef: PropTypes.shape().isRequired,
};

const s = StyleSheet.create({
  containerWhite: {
    ...cStyles.bgw,
    zIndex: 10,
  },
  navbarContainer: {
    height: 56,
  },
  searchBoxGrey: {
    backgroundColor: Colors.C_BLACK_5,
  },
});

export default withDrawerConsumer(MainHomeNavbar);
