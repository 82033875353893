import superagent from 'superagent';

import config from 'config';

import {
  K_BANNER_SLIDER_API_URL,
  K_MICROSITE_VOUCHER_SECTIONS_API_URL,
  K_VOUCHER_SLIDER_API_URL,
} from './constants';
import {
  BannerSlider,
  LoadHotspotDetailResponse,
  LoadMicrositeDetailResponse,
  LoadMoodboardDetailResponse,
  VoucherSection,
  VoucherSlider,
} from './types';

export const K_QUERY_KEY_PREFIX_LOAD_MICROSITE_DETAIL = 'microsite';
export const K_QUERY_KEY_PREFIX_LOAD_MOODBOARD_DETAIL = 'moodboard';
export const K_QUERY_KEY_PREFIX_LOAD_HOTSPOT_DETAIL = 'hotspot';

export const generateVoucherDict = (
  voucherSliders: VoucherSlider[],
  voucherSections: VoucherSection[],
) => {
  const voucherDict: Record<string, VoucherSection> = {};

  voucherSliders.forEach((voucherSlider, index) => {
    voucherDict[voucherSlider.uuid] = voucherSections[index];
  });

  return voucherDict;
};

export const getLoadBannerSliderQueryKey = (uuid: string | undefined) => {
  return [`${K_BANNER_SLIDER_API_URL}/[uuid]/`, uuid];
};

export const getLoadMicrositeDetailQueryKey = (slug: string) => [
  K_QUERY_KEY_PREFIX_LOAD_MICROSITE_DETAIL,
  slug,
];

export const getLoadMoodboardDetailQueryKey = (moodboardKey: string) => [
  K_QUERY_KEY_PREFIX_LOAD_MOODBOARD_DETAIL,
  moodboardKey,
];

export const getLoadHotspotDetailQueryKey = (hotspotKey: string) => [
  K_QUERY_KEY_PREFIX_LOAD_HOTSPOT_DETAIL,
  hotspotKey,
];

export const getLoadVoucherSliderQueryKey = (
  uuid: string,
): [string, object] => {
  return [`${K_VOUCHER_SLIDER_API_URL}/[uuid]/`, { uuid }];
};

export const getLoadMicrositeVouchersQueryKey = (
  slug: string,
): [string, object] => {
  return [`${K_MICROSITE_VOUCHER_SECTIONS_API_URL}/[slug]/`, { slug }];
};

export const loadBannerSlider = async (uuid: string): Promise<BannerSlider> => {
  const res = await superagent.get(`${K_BANNER_SLIDER_API_URL}/${uuid}/`);
  return res.body;
};

export const loadMicrositeDetail = async (
  slug: string,
): Promise<LoadMicrositeDetailResponse> => {
  const res = await superagent.get(
    `${config.API_URL_KLAW}/microsites/${slug.toLowerCase()}/`,
  );
  return res.body;
};

export const loadMoodboardDetail = async (
  moodboardKey: string,
): Promise<LoadMoodboardDetailResponse> => {
  const res = await superagent.get(
    `${config.API_URL_KLAW}/moodboards/${moodboardKey}/`,
  );
  return res.body;
};

export const loadHotspotDetail = async (
  hotspotKey: string,
): Promise<LoadHotspotDetailResponse> => {
  const res = await superagent.get(
    `${config.API_URL_KLAW}/hotspots/${hotspotKey}/`,
  );
  return res.body;
};

export const loadVoucherSlider = async (
  uuid: string,
): Promise<VoucherSection[]> => {
  const res = await superagent.get(`${K_VOUCHER_SLIDER_API_URL}/${uuid}/`);

  return res.body;
};

export const loadMicrositeVouchers = async (
  voucherSliders: VoucherSlider[],
  slug: string,
): Promise<Record<string, VoucherSection>> => {
  const query: Array<{
    name: string;
    codes: string[];
  }> = [];

  voucherSliders.forEach((voucherSlider) => {
    const voucherCodes: string[] = [];
    voucherSlider.items.forEach((voucher) => {
      voucherCodes.push(voucher.code);
    });

    query.push({
      name: voucherSlider.name,
      codes: voucherCodes,
    });
  });

  const res = await superagent
    .post(`${K_MICROSITE_VOUCHER_SECTIONS_API_URL}/${slug}/`)
    .send({ query });

  return generateVoucherDict(voucherSliders, res.body);
};
