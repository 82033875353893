import { StyleSheet } from 'react-native';
import colors from '@dkrm/ui-kit-basic/colors';
import cStyles, { K_WEIGHT_BOLD } from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  highlighted: {
    color: colors.C_BLACK_100,
    fontWeight: K_WEIGHT_BOLD,
  },

  suggestionTextContainer: {
    ...cStyles.flexLeftMiddle,
    ...cStyles.plxxxl,
  },
});
