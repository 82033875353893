import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { loadProductReviewsSummary } from 'app-libs/redux_modules/entity_modules/review';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';

import ProductReviewModal from '../ProductReviewModal';
import ProductReviewBanner from '../ProductReviewBanner';

const ProductReviewSection = () => {
  const dispatch = useDispatch();
  const productReviewSummary = useSelector(
    (state) => state.review.productReviewSummary,
  );
  const {
    numPendingReviews,
    lastPendingReview,
    shouldShowModal,
    shouldShowBanner,
  } = productReviewSummary;

  useEffect(() => {
    dispatch(
      loadAuth((_, err, user) => {
        if (!isEmpty(user)) {
          dispatch(dontShowPromptOnError(loadProductReviewsSummary()));
        }
      }),
    );
  }, [dispatch]);
  const renderProductReviewModal = () => {
    return (
      shouldShowModal &&
      Boolean(lastPendingReview) && (
        <ProductReviewModal review={lastPendingReview} />
      )
    );
  };

  const renderProductReviewBanner = () => {
    return (
      shouldShowBanner && (
        <ProductReviewBanner numPendingReviews={numPendingReviews} />
      )
    );
  };

  return (
    <>
      {renderProductReviewModal()}
      {renderProductReviewBanner()}
    </>
  );
};

export default ProductReviewSection;
