import config from 'config';

import { CityCard } from './CategoryGrid/Card';

export const K_HOUSE_SECTION_BU_TEXT = 'Pröperti ざいさん';
export const K_HOUSE_SECTION_TITLE = 'Rumah baru untuk Pasangan Baru';
export const K_HOUSE_SECTION_SUBTITLE = 'Beli rumah cashback hingga 10jt!';

export const K_HOUSE_HERO_BANNER_URL = `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/properti/dekohouse-hero-banner.png?auto=webp`;
export const K_HOUSE_HERO_BANNER_WIDTH = 329;
export const K_HOUSE_HERO_BANNER_HEIGHT = 263;

export const K_HOUSE_CATEGORIES = [
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Jakarta\nSelatan',
      avgPriceSqm: 26500000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Jak-Sel.png?auto=webp`,
      linkUrl: '/list-properti/kota/jakarta-selatan',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Jakarta\nBarat',
      avgPriceSqm: 21700000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Jak-Bar.png?auto=webp`,
      linkUrl: '/list-properti/kota/jakarta-barat',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Depok',
      avgPriceSqm: 51100000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Depok.png?auto=webp`,
      linkUrl: '/list-properti/kota/depok',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Tangerang\nSelatan',
      avgPriceSqm: 31800000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Tang-Sel.png?auto=webp`,
      linkUrl: '/list-properti/kota/tangerang-selatan',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Tangerang',
      avgPriceSqm: 15400000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Tangerang.png?auto=webp`,
      linkUrl: '/list-properti/kota/tangerang',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'BSD',
      avgPriceSqm: 21900000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/BSD.png?auto=webp`,
      linkUrl: '/list-properti/kota/tangerang/area/bsd',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Jakarta\nTimur',
      avgPriceSqm: 15900000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Jak-Tim.png?auto=webp`,
      linkUrl: '/list-properti/kota/jakarta-timur',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Bekasi',
      avgPriceSqm: 11100000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Bekasi.png?auto=webp`,
      linkUrl: '/list-properti/kota/bekasi',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Bogor',
      avgPriceSqm: 11400000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Bogor.png?auto=webp`,
      linkUrl: '/list-properti/kota/bogor',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Jakarta\nUtara',
      avgPriceSqm: 17500000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Jak-Ut.png?auto=webp`,
      linkUrl: '/list-properti/kota/jakarta-utara',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Jakarta\nPusat',
      avgPriceSqm: 28400000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Jak-Pus.png?auto=webp`,
      linkUrl: '/list-properti/kota/jakarta-pusat',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Bandung',
      avgPriceSqm: 22000000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Bandung.png?auto=webp`,
      linkUrl: '/list-properti/kota/bandung',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Kab. Bandung',
      avgPriceSqm: 21300000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Kab-Bandung.png?auto=webp`,
      linkUrl: '/list-properti/kota/kabupaten-bandung',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Kab. Bandung Barat',
      avgPriceSqm: 15500000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Kab-Bandung-Barat.png?auto=webp`,
      linkUrl: '/list-properti/kota/kabupaten-bandung-barat',
    },
  },
  {
    CardComponent: CityCard,
    props: {
      cityName: 'Cimahi',
      avgPriceSqm: 11200000,
      imgUrl: `${config.API_URL_MEDIA_CDN}/dekohouse/homepage/category/city/v2/Cimahi.png?auto=webp`,
      linkUrl: '/list-properti/kota/cimahi',
    },
  },
];

export const K_HOUSE_CTA_BTN_TITLE = '+100 Lokasi lainnya';
export const K_HOUSE_CTA_BTN_LINK_URL = '/list-properti';
