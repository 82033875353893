import React, { memo, useCallback, useMemo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { DeferredContent } from '@dkrm/ui-kit-basic';

import { useQueryLoadMoodboardDetail } from 'entities/campaign/utils';

import { ViewStyle } from 'types/styles';

import ProductMoodboard from './ProductMoodboard';
import Skeleton from './Skeleton';

interface MoodboardSectionProps {
  value: string;
  title?: string;
  containerStyle?: ViewStyle;
  titleStyle?: ViewStyle;
}

const MoodboardSection: React.FC<MoodboardSectionProps> = memo(
  ({ value, title, containerStyle, titleStyle }) => {
    const {
      data: moodboard,
      isError,
      isLoading,
    } = useQueryLoadMoodboardDetail(value);
    const shownTitle = useMemo(
      () => title || moodboard?.title || '',
      [moodboard, title],
    );

    const renderContent = useCallback(
      () =>
        !!moodboard && (
          <ProductMoodboard
            moodboard={moodboard}
            title={shownTitle}
            containerStyle={containerStyle}
            titleStyle={titleStyle}
          />
        ),
      [containerStyle, moodboard, shownTitle, titleStyle],
    );

    if (!moodboard || isError) return null;

    return (
      <DeferredContent
        animation="fade"
        isLoading={isLoading}
        renderSkeleton={() => <Skeleton />}
        renderContent={renderContent}
        style={cStyles.pvxxl}
      />
    );
  },
);

export default MoodboardSection;
