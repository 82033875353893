import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Search } from '@dkrm/icons';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import NavbarBackButton from '@dkrm/ui-kit-basic/Navbar/NavbarButton/NavbarBackButton';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { K_DEKORUMA_LOGO } from 'constants/assetConstants';
import { K_ROUTE_SEARCH_SUGGESTION } from 'constants/routeConstants';

@withRouter
export default class SearchBarPlaceholder extends PureComponent {
  static propTypes = {
    containerStyle: stylePropTypes,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    showBackButton: PropTypes.bool,
    onPress: PropTypes.func,
    searchBoxStyle: stylePropTypes,
    logoContainerStyle: stylePropTypes,
    logoStyle: stylePropTypes,
    touchableStyle: stylePropTypes,
    inputTextStyle: stylePropTypes,
    searchIconStyle: stylePropTypes,
    searchIconProps: PropTypes.shape(),
    avatarProps: PropTypes.shape(),
    logoImageUrl: PropTypes.string,
    logoImageStyle: stylePropTypes,
    showLogoPlaceholder: PropTypes.bool,
    baseUrl: PropTypes.string,

    history: PropTypes.shape().isRequired,
    router: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    placeholder: 'Cari di Dekoruma',
    value: '',
    showBackButton: false,
    onPress: null,
    containerStyle: null,
    searchBoxStyle: null,
    logoContainerStyle: null,
    logoStyle: null,
    touchableStyle: null,
    inputTextStyle: null,
    searchIconStyle: null,
    searchIconProps: {},
    avatarProps: null,
    logoImageUrl: K_DEKORUMA_LOGO,
    logoImageStyle: undefined,
    showLogoPlaceholder: false,
    baseUrl: '',
  };

  handleSearchBarClick = () => {
    const { baseUrl, onPress } = this.props;
    if (!onPress) {
      actionRouteActionOpenURL(
        baseUrl + K_ROUTE_SEARCH_SUGGESTION,
        this.props.history || this.props.router, // eslint-disable-line
      );
    } else {
      onPress();
    }
  };

  renderSearchIcon(left = false) {
    const { searchIconStyle, searchIconProps } = this.props;
    return (
      <View
        style={[
          cStyles.flexMiddle,
          cStyles.pvxl,
          left ? cStyles.plxl : cStyles.phxl,
          searchIconStyle,
        ]}
      >
        <View
          style={[cStyles.flexMiddle, { padding: 2, width: 20, height: 20 }]}
        >
          <Search size={20} theme="black" {...searchIconProps} />
        </View>
      </View>
    );
  }

  /**
   * show back button if there's possible back
   * else show search button
   */
  renderLeftIcon() {
    // current logic shows back button if value is not inserted.
    const { showBackButton } = this.props;
    if (showBackButton) {
      return (
        <View style={[cStyles.height100, { width: 48 }]}>
          <NavbarBackButton iconStyle={{ height: 16, width: 16 }} />
        </View>
      );
    }
    return this.renderSearchIcon(true);
  }

  renderMiddleComponent() {
    const {
      placeholder,
      value,
      inputTextStyle,
      showLogoPlaceholder,
      logoImageUrl,
      logoImageStyle,
    } = this.props;
    if (showLogoPlaceholder) {
      return (
        <SmartImage
          source={logoImageUrl}
          additionalQuery={{ fit: 'bounds' }}
          width={90}
          height={15}
          resizeMode="contain"
          style={[s.logoImage, logoImageStyle]}
          shouldPreloadSmallerVersion={false}
          smallerVersionRatio={2}
        />
      );
    }
    return (
      <Text
        numberOfLines={1}
        style={[
          cStyles.flex1,
          value ? cStyles.input : cStyles.inputPlaceholder,
          { borderWidth: 0, marginTop: 1, padding: 7 },
          inputTextStyle,
        ]}
      >
        {value || placeholder}
      </Text>
    );
  }

  renderRightIcon() {
    // current logic shows back button if value is not inserted.
    const { showBackButton } = this.props;
    if (!showBackButton) return null;
    return this.renderSearchIcon();
  }

  render() {
    const {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      placeholder,
      value,
      onPress,
      containerStyle,
      showBackButton,
      searchBoxStyle,
      logoContainerStyle,
      logoStyle,
      touchableStyle,
      inputTextStyle,
      avatarProps,
      logoImageUrl,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      ...rest
    } = this.props;

    const ContentComponent = (
      <View style={[s.searchBox, searchBoxStyle]}>
        {this.renderLeftIcon()}
        {this.renderMiddleComponent()}
        {this.renderRightIcon()}
      </View>
    );

    return (
      <View style={[cStyles.pvl, cStyles.phxxl, containerStyle]}>
        {onPress ? (
          <Button onPress={onPress} {...rest}>
            {() => ContentComponent}
          </Button>
        ) : (
          ContentComponent
        )}
      </View>
    );
  }
}

const s = StyleSheet.create({
  searchBox: {
    ...cStyles.flexMiddleLeft,
    ...cStyles.flexDirRow,
    ...cStyles.shadowm,
    ...cStyles.bwas,
    ...cStyles.bcm,
    ...cStyles.brxs,
    height: 45,
  },

  logoImage: {
    marginLeft: 5,
    width: 97,
    height: 32,
  },
});
