import React from 'react';

import config from 'config';

import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { cStyles } from '@dkrm/ui-kit-v2';

import { trackClickDnbBanner } from 'app-libs/trackers';

import {
  K_INTERIOR_ROOM_CATEGORIES,
  K_INTERIOR_STYLE_CATEGORIES,
} from 'components/home/constants';

import {
  K_ROUTE_DESIGN_BUILD,
  K_ROUTE_PORTFOLIO_LIST,
} from 'constants/routeConstants';

import { HeroBanner, SectionButton, SectionHeader } from '../../components';
import sectionStyles from '../styles';
import PortfolioCategorySection from './PortfolioCategorySection';

const K_BANNER_HERO_URI = `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/desain-dan-pembuatan/v5/dekohome-hero-banner-new.png?auto=webp`;
const K_WIDTH_PROPORTION = 329;
const K_HEIGHT_PROPORTION = 263;

const DnB = () => (
  <View style={sectionStyles.sectionContainer}>
    <SectionHeader
      buText="Desain Interiör インテリア"
      buTextStyle={s.buText}
      title="Desain Ruangan Sesuai Kebutuhan"
      subtitle="Miliki interior idaman dengan harga transparan"
    />
    <HeroBanner
      imgUrl={K_BANNER_HERO_URI}
      width={K_WIDTH_PROPORTION}
      height={K_HEIGHT_PROPORTION}
      to={K_ROUTE_DESIGN_BUILD}
      style={s.heroBanner}
      onPress={trackClickDnbBanner}
      buttonProps={{ anchor: true }}
    />
    <PortfolioCategorySection
      title="Desain Interior Sesuai Ruangan"
      subtitle="Koleksi inspirasi desain interior berdasarkan jenis ruangan oleh tim profesional Dekoruma."
      categoryData={K_INTERIOR_ROOM_CATEGORIES}
      containerStyle={cStyles.pt24}
    />
    <PortfolioCategorySection
      title="Desain Interior Sesuai Gaya"
      subtitle="Koleksi inspirasi gaya desain interior terbaru oleh tim profesional Dekoruma."
      categoryData={K_INTERIOR_STYLE_CATEGORIES}
      containerStyle={cStyles.pv24}
    />
    <SectionButton
      title="Lihat desain ruangan lainnya"
      linkUrl={K_ROUTE_PORTFOLIO_LIST}
    />
  </View>
);

const s = StyleSheet.create({
  heroBanner: {
    ...cStyles.mt16,
  },
  buText: {
    color: Colors.C_TERTIARY_YELLOW_3,
  },
  serviceType: {
    ...cStyles.mv4,
  },
});

export default React.memo(DnB);
