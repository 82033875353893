import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import * as authSelectors from 'app-libs/redux_modules/selectors/auth';

export const getPublicVouchers = (state) =>
  get(state, `voucher.publicVouchers.result`) || [];

export const getCustomerVouchers = (state) =>
  get(state, 'voucher.customerVouchers.result') || [];

export const getExistingCustomerVouchers = createSelector(
  getCustomerVouchers,
  authSelectors.isLoggedIn,
  (customerVouchers, isLoggedIn) => (isLoggedIn ? customerVouchers : []),
);

export const getNewCustomerVouchers = createSelector(
  getCustomerVouchers,
  authSelectors.isLoggedIn,
  (customerVouchers, isLoggedIn) => (isLoggedIn ? [] : customerVouchers),
);

export const getNewCustomerVoucherByCode = memoize((code) =>
  createSelector(getNewCustomerVouchers, (newCustomerVouchers) =>
    newCustomerVouchers.find((voucher) => voucher.code === code),
  ),
);

export const getAllProductVouchers = (state) => {
  return state?.voucher?.productVouchers?.result ?? {};
};

export const makeGetActiveProductVouchersSelector = () =>
  createSelector(
    getAllProductVouchers,
    (_, upc) => upc,
    (productVouchers, upc) =>
      productVouchers?.[upc]?.filter((voucher) => {
        return voucher.endTime * 1000 > Date.now();
      }) || [],
  );

export const getBrandVouchers = (state, brand) =>
  state.voucher.brandVouchers?.result?.[brand] ?? [];

export const isVoucherLoading = (state) =>
  isCustomerVoucherLoading(state) || isPublicVoucherLoading(state);

export const isCustomerVoucherLoading = (state) => {
  return get(state, 'voucher.customerVouchers.asyncState.loading', true);
};

export const isCustomerVoucherLoaded = (state) =>
  get(state, 'voucher.customerVouchers.asyncState.loaded', false);

export const isPublicVoucherLoading = (state) =>
  get(state, 'voucher.publicVouchers.asyncState.loading', false);

export const isPublicVoucherLoaded = (state) =>
  get(state, 'voucher.publicVouchers.asyncState.loaded', false);

export const isProductVoucherLoading = (state) =>
  get(state, 'voucher.productVouchers.asyncState.loading', false);

export const getIsBrandVouchersLoading = (state) =>
  state?.voucher?.brandVouchers?.asyncState?.loading ?? false;

export const getBrandVouchersError = (state) =>
  state.voucher.brandVouchers?.asyncState?.loadingError;

export const getAllBrandVouchers = (state) =>
  state.voucher.brandVouchers?.result;

export const makeGetIsBrandVouchersLoadedSelector = () =>
  createSelector(
    getAllBrandVouchers,
    (_, brand) => brand,
    (brandVouchers, brand) => Boolean(brandVouchers?.[brand]),
  );

export const getShouldShowSuccessGettingWelcomeVoucherModal = (state) =>
  state?.voucher?.shouldShowSuccessGettingWelcomeVoucherModal || false;
export const getShouldShowFailedGettingWelcomeVoucherModal = (state) =>
  state?.voucher?.shouldShowFailedGettingWelcomeVoucherModal || false;
