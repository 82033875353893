import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ReflowView, ResponsiveImage, StyleSheet } from '@dkrm/ui-kit-basic';

import { getImageSizeFromUrl } from 'app-libs/etc/imageHelper';

interface MoodboardImageProps {
  title: string;
  imageUrl: string;
  aspectRatio: number;
  defaultHeight?: number;
  defaultWidth?: number;
}

const MoodboardImage: React.FC<MoodboardImageProps> = memo(
  ({
    title,
    imageUrl,
    aspectRatio,
    defaultHeight = 375,
    defaultWidth = 375,
  }) => {
    const { width: widthFromUrl, height: heightFromUrl } =
      getImageSizeFromUrl(imageUrl);
    const width = widthFromUrl || defaultWidth;
    const height = aspectRatio
      ? width / aspectRatio
      : heightFromUrl || defaultHeight;
    return (
      <ReflowView widthProportion={width} heightProportion={height}>
        <ResponsiveImage
          source={{
            uri: imageUrl,
            width,
          }}
          widthProportion={width}
          heightProportion={height}
          style={s.image}
          resizeMode="contain"
          shouldPreloadSmallerVersion={false}
          smallerVersionRatio={1}
          accessibilityLabel={title}
        />
      </ReflowView>
    );
  },
);

const s = StyleSheet.create({
  image: {
    ...cStyles.flex1,
    overflow: 'hidden',
  },
});

export default MoodboardImage;
