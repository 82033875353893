import PropTypes from 'prop-types';
import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ReflowView, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { slugify } from 'app-libs/etc';
import { getHostnameFromUrl, getPathFromUrl } from 'app-libs/etc/routeHelper';
import { useLocation } from 'app-libs/hook_modules/router';
import { trackClickHomepageBanner } from 'app-libs/trackers';

import { K_DKRMA_HOSTNAME, K_ROUTE_CAMPAIGNS } from 'constants/routeConstants';

const K_BANNER_WIDTH_PROPORTION = 375;
const K_BANNER_HEIGHT_PROPORTION = 300;

const CampaignSliderItem = ({ imageUrl, name, url, index }) => {
  const linkUrl = url || `${K_ROUTE_CAMPAIGNS}/${slugify(name)}`; // prettier-ignore
  const shouldUsingAcnhor = getHostnameFromUrl(linkUrl) === K_DKRMA_HOSTNAME;

  const location = useLocation();
  const pathname = location?.pathname || '';
  return (
    <View onMoveShouldSetResponder={() => true}>
      <ButtonWrapper
        linkProps={{
          isUsingAnchor: shouldUsingAcnhor,
        }}
        to={{
          pathname: getPathFromUrl(linkUrl),
          state: { openedFrom: pathname },
        }}
        onPress={() => trackClickHomepageBanner(linkUrl, index)}
      >
        {() => (
          <View style={cStyles.flex1}>
            <ReflowView
              widthProportion={K_BANNER_WIDTH_PROPORTION}
              heightProportion={K_BANNER_HEIGHT_PROPORTION}
            >
              <SmartImage
                accessibilityLabel={name}
                shouldPreloadSmallerVersion={index > 0}
                source={imageUrl ?? ''}
                sizes="100vw"
                style={cStyles.flex1}
                resizeMode="contain"
              />
            </ReflowView>
          </View>
        )}
      </ButtonWrapper>
    </View>
  );
};

CampaignSliderItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default CampaignSliderItem;
