import React from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView } from '@dkrm/ui-kit-basic';
import {
  HeadComponent,
  HeadContainer,
  HeadroomProvider,
  ScrollableComponent,
} from '@dkrm/ui-kit-basic/Headroom';

import { ShopByRoomCarousel } from 'app-libs/components/ShopByRoom';

import LocationNavigation from 'components/LocationNavigation';
import {
  MainHomeNavbar,
  NewCustomerVoucherSection,
  ProductReviewSection,
} from 'components/home/MainHome/components';
import {
  BusinessUnit,
  CampaignSlider,
  DEC,
  DnB,
  House,
  InstagramFeed,
  MediaReview,
  RetailTitleSection,
  SecondaryBanner,
  ULPSection,
} from 'components/home/MainHome/sections';
import MarketplaceCategory from 'components/home/shared/sections/MarketplaceCategory';
import FloatingIcon from 'components/promo/Shared/FloatingIcon/Loadable';
import PopupBanner from 'components/promo/Shared/PopupBanner/Loadable';

import ExclusiveBrandSection from './sections/ExclusiveBrandSection';
import JapandiSection from './sections/JapandiSection';
import TopProductsMoodboard from './sections/TopProductsMoodboard';
import { useEmailValidationEffect } from './utils';

const renderNull = () => null;

interface MainHomeProps {
  renderFooter?: () => JSX.Element;
}

const MainHome: React.FC<MainHomeProps> = (props) => {
  const { renderFooter = renderNull } = props;

  useEmailValidationEffect();

  return (
    <HeadroomProvider defaultHeight={80}>
      <HeadContainer style={{ zIndex: 1, ...cStyles.shadowm }}>
        <MainHomeNavbar />
        <HeadComponent>
          <LocationNavigation />
        </HeadComponent>
      </HeadContainer>
      <ScrollableComponent component={ScrollView}>
        <CampaignSlider />
        <NewCustomerVoucherSection />
        {config.ENABLE_ULP_FEATURES && <ULPSection />}
        <ProductReviewSection />
        <BusinessUnit />
        <SecondaryBanner />
        <TopProductsMoodboard />
        <RetailTitleSection />
        <MarketplaceCategory
          shouldHideTitle
          shouldHideUSP
          shouldHideShopByRoom
          source="homepage"
        />
        <ShopByRoomCarousel />
        <ExclusiveBrandSection />
        <JapandiSection />
        <DnB />
        <House />
        <DEC />
        <MediaReview />
        <InstagramFeed />
        {renderFooter()}
      </ScrollableComponent>
      <PopupBanner />
      <FloatingIcon />
    </HeadroomProvider>
  );
};

export default MainHome;
