import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet } from '@dkrm/ui-kit-basic';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

import MoodboardSection from 'components/microsite/MoodboardSection';

import { K_TOP_PRODUCTS_MOODBOARD_ID } from '../../constants';

const TopProductsMoodboard: React.FC = memo(() => {
  return (
    <MoodboardSection
      value={K_TOP_PRODUCTS_MOODBOARD_ID}
      containerStyle={s.moodboardContainer}
      titleStyle={s.title}
    />
  );
});

const s = StyleSheet.create({
  moodboardContainer: {
    ...cStyles.pvn,
    ...cStyles.mtxl,
  },
  title: {
    ...tStyles.h4,
  },
});

export default TopProductsMoodboard;
