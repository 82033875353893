import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getProductCategory } from 'app-libs/redux_modules/entity_modules/selectors/product';
import {
  K_OFFER_TYPE_CREDIT_CARD,
  K_PROMO_LABEL_DISCOUNT_TYPES,
  K_PROMO_LABEL_SHIPPING_TYPES,
  K_PROMO_TYPE_CASHBACK,
  K_PROMO_TYPE_DISCOUNT,
  K_PROMO_TYPE_PAYMENT,
  K_PROMO_TYPE_SHIPPING,
  K_PROMO_TYPE_USER_VOUCHERS,
  K_VOUCHER_ISSUER_MERCHANT,
} from 'constants/promoConstants';

import { dayjs } from './dateHelperV2';

export const getOfferLabel = (offer) => {
  if (offer.offerType === K_OFFER_TYPE_CREDIT_CARD) return K_PROMO_TYPE_PAYMENT;
  const paymentMethods = get(offer, 'voucher.paymentMethods', []);
  if (paymentMethods.length) return K_PROMO_TYPE_PAYMENT;
  if (!offer.discountValue) return K_PROMO_TYPE_CASHBACK;
  if (K_PROMO_LABEL_SHIPPING_TYPES.includes(offer.discountType))
    return K_PROMO_TYPE_SHIPPING;
  if (K_PROMO_LABEL_DISCOUNT_TYPES.includes(offer.discountType))
    return K_PROMO_TYPE_DISCOUNT;
  return '';
};

export const isVoucherApplicableForSelectedCoverage = (
  voucher,
  selectedCoverage,
  shippingCharge = null,
) =>
  (isEmpty(voucher.offerLocationProvince) &&
    isEmpty(voucher.offerLocationCity)) ||
  ((voucher.offerLocationCity.includes(
    selectedCoverage?.line4 || selectedCoverage?.city,
  ) ||
    voucher.offerLocationProvince.includes(
      selectedCoverage?.state || selectedCoverage?.province,
    )) &&
    !shippingCharge?.unshippableItems?.length);

export const isVoucherAvailableForAnyLine = (voucher, lines) => {
  if (voucher.includesAllProducts) return true;

  return lines.some((line) => {
    const { product } = line;
    const { brand, objectID } = product;
    if (voucher.includedProducts.includes(objectID)) {
      return true;
    }

    const category = getProductCategory(product);
    const hasIncludedBrands = Boolean(voucher.includedBrands.length);
    const hasIncludedCategories = Boolean(voucher.includedCategories.length);
    if (
      (hasIncludedBrands || hasIncludedCategories) &&
      (!hasIncludedBrands || voucher.includedBrands.includes(brand)) &&
      (!hasIncludedCategories || voucher.includedCategories.includes(category))
    ) {
      return true;
    }
    return false;
  });
};

export const getVoucherByPromoType = (vouchers, promoType) => {
  return vouchers.find(
    (voucher) =>
      promoType.includes(voucher.discountType) &&
      voucher.voucherIssuer !== K_VOUCHER_ISSUER_MERCHANT,
  );
};

export const getVoucherCodeByPromoType = (vouchers, promoType) => {
  const foundVoucher = getVoucherByPromoType(vouchers, promoType);
  return foundVoucher?.code ?? '';
};

export const getProductVoucherCodeFromVoucherDiscounts = (voucherDiscounts) => {
  const voucherDiscount =
    (voucherDiscounts || []).find((discount) => {
      return (
        K_PROMO_LABEL_DISCOUNT_TYPES.includes(discount.discountType) &&
        discount.voucherIssuer !== K_VOUCHER_ISSUER_MERCHANT
      );
    }) || {};
  return voucherDiscount?.code ?? '';
};

export const getShippingVoucherCodeFromVoucherDiscounts = (
  voucherDiscounts,
) => {
  const voucherDiscount =
    (voucherDiscounts || []).find(
      (discount) =>
        K_PROMO_LABEL_SHIPPING_TYPES.includes(discount.discountType) &&
        discount.voucherIssuer !== K_VOUCHER_ISSUER_MERCHANT,
    ) || {};
  return voucherDiscount?.code ?? '';
};

export const isVoucherAvailableToday = (voucher) => {
  if (!voucher.recurringDays || voucher.recurringDays.length === 0) return true;

  const currentDayOfTheWeek = dayjs().weekday();
  return voucher.recurringDays.some(
    ({ dayOfTheWeek }) => dayOfTheWeek === currentDayOfTheWeek,
  );
};

export const isVoucherStoreLocationsIncludeUserCurrentStoreLocation = (
  voucher,
  userStoreCode,
) => {
  if (!voucher.voucherStoreLocations) return true;
  if (voucher.voucherStoreLocations.length === 0) return true;
  if (userStoreCode === '') return false;

  return voucher.voucherStoreLocations.some(
    ({ code }) => userStoreCode === code,
  );
};

export const isOfflineVoucher = (voucher) => {
  return Boolean(voucher.isOfflineOnly);
};

export const isUserVoucher = (voucher) =>
  voucher.promoType === K_PROMO_TYPE_USER_VOUCHERS;

export const isLinesContainTradeIn = (addonProducts) => {
  const addonNames = addonProducts.map((addonProduct) => {
    return addonProduct.addonName;
  });
  return addonNames.includes('Trade In');
};

export const isVoucherCodeExists = (vouchers, voucherCode) => {
  return Boolean(vouchers.find(({ code }) => voucherCode === code));
};

export const sortVouchersByRankByDiscount = (vouchers) => {
  return vouchers.sort((voucher, nextVoucher) => {
    if (voucher.rank > nextVoucher.rank) return 1;
    if (voucher.rank < nextVoucher.rank) return -1;

    if (
      voucher.maximumApplicableDiscountAmount <
      nextVoucher.maximumApplicableDiscountAmount
    )
      return 1;
    if (
      voucher.maximumApplicableDiscountAmount >
      nextVoucher.maximumApplicableDiscountAmount
    )
      return -1;

    return 0;
  });
};

export const getVoucherByCode = (vouchers, code) => {
  return vouchers.find((voucher) => voucher.code === code);
};
