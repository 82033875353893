import superagent from 'superagent';

import config from 'config';

import { UnseenNotificationsCountData, UserNotification } from './types';

export const getLoadUnseenNotificationsCountQueryKey = () => [
  'unseen-notifications-count',
];

export const getLoadUserNotificationsQueryKey = () => ['user-notifications'];

export const loadUnseenNotificationsCount =
  async (): Promise<UnseenNotificationsCountData> => {
    const res = await superagent
      .get(`${config.API_URL_NOTIF}/v1/notifications/unseen-notifications/`)
      .withCredentials();

    return res.body;
  };

export const loadUserNotifications = async (): Promise<UserNotification[]> => {
  const res = await superagent
    .get(`${config.API_URL_NOTIF}/v1/notifications/user-notifications/`)
    .withCredentials();

  return res.body;
};

export const markAllNotificationsAsSeen = async (
  email: string,
): Promise<UserNotification[]> => {
  const res = await superagent
    .post(
      `${config.API_URL_NOTIF}/v1/notifications/mark-all-notifications-as-seen/`,
    )
    .send({ email })
    .withCredentials();

  return res.body;
};
