import PropTypes from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { trackClickedSectionSeeOtherHomepage } from 'app-libs/trackers';

const emptyObject = {};

const SectionButton = ({ title, linkUrl, linkProps, style }) => {
  const trackClickedSeeOtherBtn = () => {
    trackClickedSectionSeeOtherHomepage(linkUrl);
  };

  return (
    <ButtonWrapper
      to={linkUrl}
      linkProps={linkProps}
      onPress={trackClickedSeeOtherBtn}
      hoverableViewStyle={style}
    >
      {() => (
        <View style={s.container}>
          <Text theme="h5-tosca" style={s.titleText}>
            {title}
          </Text>
        </View>
      )}
    </ButtonWrapper>
  );
};

SectionButton.propTypes = {
  title: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkProps: PropTypes.shape(),
  style: stylePropTypes,
};

SectionButton.defaultProps = {
  linkProps: emptyObject,
  style: null,
};

const s = StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.flexMiddle,
    ...cStyles.brs,
    backgroundColor: colors.C_SECONDARY_TOSCA_1,
  },
  titleText: {
    ...cStyles.mvm,
  },
});

export default SectionButton;
