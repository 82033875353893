import React from 'react';

import { View, StyleSheet, Colors } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { trackClickHouseBanner } from 'app-libs/trackers';

import { K_ROUTE_HOUSE } from 'constants/routeConstants';

import { HeroBanner, SectionHeader, SectionButton } from '../../components';

import HouseCategoryGrid from './CategoryGrid';

import sectionStyles from '../styles';

import {
  K_HOUSE_SECTION_BU_TEXT,
  K_HOUSE_HERO_BANNER_URL,
  K_HOUSE_HERO_BANNER_WIDTH,
  K_HOUSE_HERO_BANNER_HEIGHT,
  K_HOUSE_SECTION_TITLE,
  K_HOUSE_SECTION_SUBTITLE,
  K_HOUSE_CTA_BTN_TITLE,
  K_HOUSE_CTA_BTN_LINK_URL,
} from './constants';

const HouseSection = () => (
  <View style={sectionStyles.sectionContainer}>
    <SectionHeader
      buText={K_HOUSE_SECTION_BU_TEXT}
      buTextStyle={s.buText}
      title={K_HOUSE_SECTION_TITLE}
      subtitle={K_HOUSE_SECTION_SUBTITLE}
    />
    <HeroBanner
      imgUrl={K_HOUSE_HERO_BANNER_URL}
      width={K_HOUSE_HERO_BANNER_WIDTH}
      height={K_HOUSE_HERO_BANNER_HEIGHT}
      to={K_ROUTE_HOUSE}
      style={s.heroBanner}
      onPress={trackClickHouseBanner}
      buttonProps={{ anchor: true }}
    />
    <HouseCategoryGrid />
    <SectionButton
      title={K_HOUSE_CTA_BTN_TITLE}
      linkUrl={K_HOUSE_CTA_BTN_LINK_URL}
      linkProps={{ isUsingAnchor: true }}
    />
  </View>
);

const s = StyleSheet.create({
  heroBanner: {
    ...cStyles.mvxl,
  },
  buText: {
    color: Colors.C_SECONDARY_RED_3,
  },
});

export default React.memo(HouseSection);
