import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Star } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Image } from '@dkrm/ui-kit-basic/v3';

import BrandLogoContainer from 'app-libs/components/brand/BrandHeader/BrandLogoContainer';
import { slugify } from 'app-libs/etc';
import {
  getNumberInHumanReadableText,
  getRoundedRating,
} from 'app-libs/etc/numberHelper';

import { useQueryLoadBrandRating } from 'entities/review/utils';

import { K_PRIVATE_LABEL_TENZO_LIVING } from 'constants/productConstants';
import { K_ROUTE_BRANDS } from 'constants/routeConstants';

import { BrandItem } from '../type';

interface ExclusiveBrandItemProps {
  brandItem: BrandItem;
}

const K_ICON_SIZE = 16;
const K_LOGO_SIZE = 48;
const K_CARD_WIDTH = 144;

const ExclusiveBrandItem: React.FC<ExclusiveBrandItemProps> = memo(
  ({ brandItem }) => {
    const { brand, title, subtitle, imageUrl } = brandItem;

    const { data: brandRating, isLoading } = useQueryLoadBrandRating(brand);
    let rating = brandRating?.averageRating || 0;
    let numRatings = brandRating?.numRatings || 0;

    const linkUrl = `${K_ROUTE_BRANDS}/${slugify(brand)}`;
    let shownRating = getRoundedRating(rating);
    let shownNumRatingText = `${getNumberInHumanReadableText(
      numRatings,
    )} Ulasan`;

    // @note(dika) 5 Mar 2024: Hardcode number of rating in Tenzo Living temporarily
    if (brand === K_PRIVATE_LABEL_TENZO_LIVING) {
      rating = 4.8;
      numRatings = 50;
      shownRating = '4.8';
      shownNumRatingText = '50+ Penjualan';
    }

    return (
      <Button to={linkUrl} hoverableViewStyle={s.container}>
        {() => (
          <>
            <Image
              source={imageUrl}
              width={K_CARD_WIDTH}
              height={K_CARD_WIDTH}
              style={s.mainImage}
              resizeMode="contain"
            />
            <BrandLogoContainer
              brand={brand}
              containerStyle={s.logoContainer}
            />
            <View style={s.descriptionContainer}>
              <Text theme="h5-neutral900" style={cStyles.mbs}>
                {title}
              </Text>
              <Text theme="body5-neutral100" style={s.subtitle}>
                {subtitle}
              </Text>
              {((rating > 0 && numRatings > 0) || isLoading) && (
                <View style={s.ratingContainer}>
                  <Star theme="yellow" size={K_ICON_SIZE} style={cStyles.mrs} />
                  <Text theme="body5-neutral100" style={cStyles.mtxs}>
                    {isLoading
                      ? 'Loading ...'
                      : `${shownRating} (${shownNumRatingText})`}
                  </Text>
                </View>
              )}
            </View>
          </>
        )}
      </Button>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.brl,
    ...cStyles.bwam,
    ...cStyles.flex1,
    width: K_CARD_WIDTH,
    borderColor: Colors.C_NEUTRAL_30,
  },
  mainImage: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  logoContainer: {
    ...cStyles.abs,
    top: K_CARD_WIDTH - K_LOGO_SIZE / 2,
    left: 16,
  },
  descriptionContainer: {
    ...cStyles.phm,
    marginTop: 28,
  },
  subtitle: {
    ...cStyles.mbs,
    fontSize: 10,
  },
  ratingContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mbxl,
  },
});

export default ExclusiveBrandItem;
