import React, { memo } from 'react';

import { View, FlatList } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { K_SHOP_BY_ROOM_ITEMS } from '../constants';
import { ShopByRoomItem } from '../type';
import Card from '../Card';

const K_DEFAULT_WIDTH = 375;
const K_CARD_WIDTH_PERCENTAGE = 0.35;

const ShopByRoomCarousel: React.FC = memo(() => {
  const cardWidth = K_DEFAULT_WIDTH * K_CARD_WIDTH_PERCENTAGE;
  const style = {
    width: cardWidth,
  };

  return (
    <View style={cStyles.mtxl}>
      <FlatList
        horizontal
        data={K_SHOP_BY_ROOM_ITEMS}
        contentContainerStyle={cStyles.phxl}
        renderItem={({ item }: { item: ShopByRoomItem }) => (
          <Card roomItem={item} style={style} />
        )}
        initialNumToRender={4}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item: ShopByRoomItem) => item.title}
      />
    </View>
  );
});

export default ShopByRoomCarousel;
