import React, { memo } from 'react';

import { Rectangle } from '@dkrm/ui-kit-basic';
import { StyleSheet, View, cStyles } from '@dkrm/ui-kit-v2';

const ULPSectionSkeleton: React.FC = memo(() => {
  return (
    <View style={s.container}>
      <Rectangle style={s.containerContentSkeleton} />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.br4,
    ...cStyles.shadowOverlay,
    ...cStyles.mt16,
    ...cStyles.mh16,
    ...cStyles.pv8,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    height: 40,
  },
  containerContentSkeleton: {
    ...cStyles.mt16,
    height: 40,
  },
});

export default ULPSectionSkeleton;
