import React, { memo, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet } from '@dkrm/ui-kit-basic';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import ProductCard from 'app-libs/components/Product/ProductCard';
import ProductRecommendationList from 'app-libs/components/Product/ProductRecommendationList';
import { Product } from 'app-libs/components/Product/types';
import { getCDNImageUrl } from 'app-libs/etc/imageHelper';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';
import { makeGetProductsFromEntitiesByUpcsSelector } from 'app-libs/redux_modules/entity_modules/selectors/productV2';
import { trackClickProductFromMoodBoard } from 'app-libs/trackers';

import { Moodboard } from 'entities/campaign/types';

import { K_ROUTE_MOODBOARD } from 'constants/routeConstants';
import {
  K_PROPERTY_OPENED_FROM_MOODBOARD,
  K_TRACK_CLICKED_PRODUCT_FROM_MOODBOARD,
} from 'constants/trackingConstants';

import { ViewStyle } from 'types/styles';

import MoodboardImage from './MoodboardImage';
import { getShownProducts } from './utils';

const K_DEFAULT_BANNER_ASPECT_RATIO = 1;

interface ProductMoodboardProps {
  moodboard: Moodboard;
  title: string;
  containerStyle?: ViewStyle;
  titleStyle?: ViewStyle;
}

const ProductMoodboard: React.FC<ProductMoodboardProps> = memo(
  ({ moodboard, title, containerStyle, titleStyle }) => {
    const getProductsFromEntitiesByUpcs: (
      state: RootStateOrAny,
      upcs: string[],
    ) => Product[] = useMemo(makeGetProductsFromEntitiesByUpcsSelector, []);

    const moodboardProducts = moodboard?.products ?? [];
    const productUpcs = moodboardProducts.map(
      (moodboardProduct) => moodboardProduct.upc,
    );

    const routingZoneCode = useSelector(getCurrentLocationRoutingZoneCode);

    const products = useSelector((state) =>
      getProductsFromEntitiesByUpcs(state, productUpcs),
    );
    const imageUrl = moodboard.banner ? getCDNImageUrl(moodboard.banner) : null;

    const bannerAspectRatio = moodboard.bannerAspectRatio
      ? parseFloat(moodboard.bannerAspectRatio)
      : K_DEFAULT_BANNER_ASPECT_RATIO;

    const availableProducts = useMemo(
      () => getShownProducts(products, routingZoneCode),
      [products, routingZoneCode],
    );

    return (
      <>
        {!!imageUrl && (
          <MoodboardImage
            imageUrl={imageUrl}
            title={title}
            aspectRatio={bannerAspectRatio}
          />
        )}
        <ProductRecommendationList
          ProductCardComponent={ProductCard}
          recommendationProducts={availableProducts}
          title={title}
          titleStyle={[s.title, titleStyle]}
          headerStyle={s.header}
          productSection={title}
          contentStyle={cStyles.plm}
          containerStyle={containerStyle}
          trackingReferrerName={K_PROPERTY_OPENED_FROM_MOODBOARD}
          trackingEventName={K_TRACK_CLICKED_PRODUCT_FROM_MOODBOARD}
          trackOnPressProductChildren={trackClickProductFromMoodBoard}
          RightButton={
            <ButtonWithText
              small
              to={`${K_ROUTE_MOODBOARD}/${moodboard.uuid}`}
              buttonStyle={s.seeAllContainer}
              title="Lihat Semua"
              theme="tosca"
              themeType="tertiary"
              textStyle={s.seeAllText}
            />
          }
        />
      </>
    );
  },
);

const s = StyleSheet.create({
  header: {
    ...cStyles.phxl,
    ...cStyles.mbm,
  },
  seeAllContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.pts,
    ...cStyles.pbm,
    ...cStyles.prl,
    paddingLeft: 40,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    ...tStyles.h5,
    ...cStyles.flex1,
    ...cStyles.flexMiddleLeft,
    ...cStyles.pbm,
    display: 'flex',
    height: 48,
  },
  seeAllText: {
    ...cStyles.fwb,
    color: Colors.C_SECONDARY_TOSCA_4,
  },
});

export default ProductMoodboard;
