import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Text, View, TouchableOpacity } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Close } from '@dkrm/icons';
import { closeProductReviewBanner } from 'app-libs/redux_modules/entity_modules/review';
import { trackClickedProductReviewBanner } from 'app-libs/trackers';

import { K_ROUTE_REVIEW_LIST } from 'constants/routeConstants';

import cStyles from '@dkrm/general-libs/theme/styles';
import styles from './styles';

const ProductReviewBanner = ({ numPendingReviews }) => {
  const [isBannerClosed, setIsBannerClosed] = useState(false);
  const dispatch = useDispatch();
  const closeBanner = useCallback(() => {
    setIsBannerClosed(true);
    dispatch(closeProductReviewBanner());
  }, [dispatch]);
  if (isBannerClosed || numPendingReviews === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      <View style={styles.bannerContentContainer}>
        <View style={cStyles.flexDirCol}>
          <Text theme="h5" style={styles.headlineText}>
            Bantu kasih review, yuk!
          </Text>
          <Text theme="body4-black50">Untuk kesempatan dapat voucher</Text>
        </View>
        <View style={[cStyles.abs, { right: 24 }]}>
          <Button
            onPress={trackClickedProductReviewBanner}
            to={K_ROUTE_REVIEW_LIST}
            style={{ marginVertical: 6 }}
          >
            {() => (
              <View style={styles.reviewButton}>
                <Text theme="h6-white">Review</Text>
              </View>
            )}
          </Button>
        </View>
      </View>
      <TouchableOpacity
        style={styles.closeButtonContainer}
        onPress={closeBanner}
      >
        <Close
          style={[cStyles.mts, { marginLeft: 9 }]}
          size={13}
          theme="black"
        />
      </TouchableOpacity>
    </View>
  );
};

ProductReviewBanner.propTypes = {
  numPendingReviews: PropTypes.number.isRequired,
};

export default ProductReviewBanner;
