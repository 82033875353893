import { isEqual } from 'lodash';
import get from 'lodash/get';
import has from 'lodash/has';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  ALGOLIA_DEFAULT_INDEX_NAME,
  ALGOLIA_PRODUCT_SUGGESTION_INDEX_NAME,
} from 'config';

import { createSearchAdapter } from '@dkrm/algolia-adapter';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { View } from '@dkrm/ui-kit-basic';
import { actionRouteActionOpenURL, goBack } from '@dkrm/ui-kit-basic/Router';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import { makeParametricUrl, slugify } from 'app-libs/etc';
import { getUrlWithQuery } from 'app-libs/etc/routeHelper';
import { loadProductCategoryTree } from 'app-libs/redux_modules/flow_modules/category';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import {
  getIsLoadedProductCategoryTree,
  getIsLoadingProductCategoryTree,
  getProductCategoryTree,
} from 'app-libs/redux_modules/flow_modules/selectors/category';
import { category } from 'app-libs/serializers/category';
import { trackSearchQueryChange } from 'app-libs/trackers';

import {
  K_MARKETPLACE_PRODUCT,
  K_SEARCH_SUGGESTION_LIST_POST_KEY,
  K_SEARCH_SUGGESTION_LIST_PRODUCT_KEY,
} from 'constants/productConstants';
import {
  K_ROUTE_BRAND_DETAIL,
  K_ROUTE_POST_SEARCH,
  K_ROUTE_PRODUCT_SIMPLIFIED,
  K_ROUTE_SEARCH,
  K_ROUTE_TOP_SEARCH,
} from 'constants/routeConstants';

import SearchBar from '../SearchBar';
import {
  findBrandFromQuery,
  findUpcFromQuery,
  generateBreadcrumbFromQuery,
} from './SearchHelper';
import SuggestionList from './SuggestionList';
import styles from './styles';

const K_REDIRECT_CATEGORY_SLUG_BY_EMPTY_CATEGORY_SLUG = {
  Tirai: '/Tirai-&-Gorden',
  Gorden: '/Tirai-&-Gorden',
};

@withRouter
@connect((state) => ({
  categoryTree: getProductCategoryTree(state),
  isLoadingCategoryTree: getIsLoadingProductCategoryTree(state),
  isLoadedCategoryTree: getIsLoadedProductCategoryTree(state),
}))
export default class SearchSuggestion extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
    FilterComponent: PropTypes.element,
    baseURL: PropTypes.string,
    algoliaIndex: PropTypes.string,
    fullBar: PropTypes.bool,
    shouldRenderCancelButton: PropTypes.bool,
    selectedSuggestionIdx: PropTypes.number,
    searchBarProps: PropTypes.shape(),
    suggestionListStyle: stylePropTypes,
    categoryTree: PropTypes.shape().isRequired,
    config: PropTypes.shape(),
    isLoadedCategoryTree: PropTypes.bool.isRequired,
    isLoadingCategoryTree: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    findTopSearchQueryFromQuery: PropTypes.func,
  };

  static defaultProps = {
    onCancel: null,
    forwardRef: undefined,
    FilterComponent: null,
    baseURL: '',
    algoliaIndex: ALGOLIA_PRODUCT_SUGGESTION_INDEX_NAME,
    fullBar: false,
    shouldRenderCancelButton: true,
    selectedSuggestionIdx: 0,
    searchBarProps: {},
    suggestionListStyle: null,
    config: {},
    findTopSearchQueryFromQuery: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      shouldHideSuggestion: false,
      query: '',
      suggestions: [],
      selectedSuggestionIdx: 0,
      brands: [],
      // Generate category tree to handle user search for specific category
      allCategoryBreadcrumbs: category
        .initWithCategoryTree(props.categoryTree)
        .getBreadcrumbs(),
    };

    this.searchAdapter = createSearchAdapter();
    this.searchAdapter.setState({
      disjunctiveFacetsRefinements: {
        'goblin_products3.facets.exact_matches.catalogueGroupNames.value': [
          K_MARKETPLACE_PRODUCT,
        ],
      },
      hitsPerPage: 10,
    });
    this.searchAdapter.setIndex(props.algoliaIndex);

    // handle user search a brand
    this.brandAdapter = createSearchAdapter();
    this.brandAdapter.setState({
      disjunctiveFacets: ['brand'],
      disjunctiveFacetsRefinements: {
        isBlacklisted: ['-true'],
        catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
      },
      maxValuesPerFacet: 1000,
    });
    this.brandAdapter.setIndex(ALGOLIA_DEFAULT_INDEX_NAME);
  }

  componentDidMount() {
    this.searchAdapter.subscribe('result', (searchResult) => {
      this.setState({
        suggestions: searchResult.hits,
        query: searchResult.query,
      });
    });

    this.brandAdapter.subscribe('result', (searchResult) => {
      const { disjunctiveFacets } = searchResult;
      this.setState({
        brands: Object.keys(
          get(
            disjunctiveFacets.find((facet) => facet.name === 'brand'),
            'data',
            {},
          ),
        ),
      });
    });
    const { isLoadedCategoryTree, isLoadingCategoryTree, dispatch } =
      this.props;

    if (!isLoadingCategoryTree && !isLoadedCategoryTree) {
      dispatch(dontShowPromptOnError(loadProductCategoryTree()));
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedSuggestionIdx, categoryTree } = this.props;
    if (prevProps.selectedSuggestionIdx !== selectedSuggestionIdx) {
      this.handleUpdateSelectedSuggestionIdx();
    }

    if (!isEqual(prevProps.categoryTree, categoryTree)) {
      this.updateAllBreadcrumbs(categoryTree);
    }
  }

  componentWillUnmount() {
    this.searchAdapter.unsubscribe('result');
    this.brandAdapter.unsubscribe('result');
  }

  updateAllBreadcrumbs = (categoryTree) => {
    this.setState({
      allCategoryBreadcrumbs: category
        .updateCategoryTree(categoryTree)
        .getBreadcrumbs(),
    });
  };

  handleClearInput = () => {
    this.searchAdapter.setQuery('');
    this.searchAdapter.search();
  };

  handlePostSearch = (query) => {
    const { history } = this.props;
    const destinationURL = getUrlWithQuery(K_ROUTE_POST_SEARCH, { query });
    actionRouteActionOpenURL(destinationURL, history);
  };

  handleProductSearch = (query) => {
    const { baseURL, config, history, findTopSearchQueryFromQuery } =
      this.props;
    const { brands, allCategoryBreadcrumbs } = this.state;
    const breadcrumbs = generateBreadcrumbFromQuery(
      query,
      allCategoryBreadcrumbs,
    );
    const brand = findBrandFromQuery(query, brands);
    const upc = findUpcFromQuery(query);
    const topSearchQuery = findTopSearchQueryFromQuery(query.trim());
    let destinationURL = baseURL;
    let routeOptions = {};

    if (topSearchQuery) {
      destinationURL += makeParametricUrl(K_ROUTE_TOP_SEARCH, {
        querySlug: slugify(topSearchQuery),
      });
    } else if (upc) {
      destinationURL += makeParametricUrl(K_ROUTE_PRODUCT_SIMPLIFIED, {
        pid: upc,
      });
      routeOptions.isUsingAnchor = true;
    } else if (brand) {
      destinationURL += makeParametricUrl(K_ROUTE_BRAND_DETAIL, {
        bslug: slugify(brand),
      });
    } else if (breadcrumbs) {
      const categoryPage = breadcrumbs.split(' > ').pop();
      if (categoryPage in K_REDIRECT_CATEGORY_SLUG_BY_EMPTY_CATEGORY_SLUG) {
        destinationURL +=
          K_REDIRECT_CATEGORY_SLUG_BY_EMPTY_CATEGORY_SLUG[categoryPage];
      } else if (categoryPage in config) {
        destinationURL = config[categoryPage].to;
        routeOptions = config[categoryPage].options;
      } else {
        destinationURL = `/${slugify(categoryPage)}`;
      }
    } else {
      destinationURL += getUrlWithQuery(K_ROUTE_SEARCH, { query });
    }
    trackSearchQueryChange(query, destinationURL);
    actionRouteActionOpenURL(destinationURL, history, routeOptions);
  };

  handleSearchSubmit = (query) => {
    const { baseURL } = this.props;
    if (query.length) {
      this.actionHideSuggestions();
      this.actionSetQueryInSearchBar(query);
      if (baseURL.startsWith(K_ROUTE_POST_SEARCH)) {
        this.handlePostSearch(query);
      } else {
        this.handleProductSearch(query);
      }
    }
  };

  handleSearchQueryChange = (query) => {
    this.searchAdapter.setQuery(query);
    this.searchAdapter.search();
  };

  handleUpdateSelectedSuggestionIdx = () => {
    const { baseURL, selectedSuggestionIdx } = this.props;
    const { suggestions, query } = this.state;

    if (!suggestions || suggestions.length <= 0) return;

    const key = baseURL.startsWith(K_ROUTE_POST_SEARCH)
      ? K_SEARCH_SUGGESTION_LIST_POST_KEY
      : K_SEARCH_SUGGESTION_LIST_PRODUCT_KEY;

    let newQuery = query;
    const suggestionLen = suggestions.length + 1;
    const newIdx =
      ((selectedSuggestionIdx % suggestionLen) + suggestionLen) % suggestionLen;
    if (newIdx === 0) {
      newQuery = this.searchAdapter.getQuery();
    } else {
      newQuery = suggestions[newIdx - 1][key];
    }
    this.actionSetQueryInSearchBar(newQuery);
    if (query !== newQuery) {
      this.setState({
        query: newQuery,
        selectedSuggestionIdx: newIdx,
      });
    }
  };

  actionCancelSearch = () => {
    const { history, onCancel } = this.props;
    if (onCancel) {
      return onCancel();
    }
    return goBack(history);
  };

  actionHideSuggestions = () => this.setState({ shouldHideSuggestion: true });

  actionShowSuggestions = () => {
    const { query, suggestions } = this.state;
    if (query && suggestions) this.setState({ shouldHideSuggestion: false });
  };

  actionSetQueryInSearchBar = (query) => {
    const { forwardRef } = this.props;
    if (has(forwardRef, 'current.searchBarBasicRef')) {
      const { searchBarBasicRef } = forwardRef.current;
      const { textInputRef } = searchBarBasicRef.current;
      textInputRef.current.setNativeProps({ text: query });
    }
  };

  renderSearchBarWithOrWithoutCancelButton() {
    const { query } = this.state;
    const { forwardRef, shouldRenderCancelButton, searchBarProps, fullBar } =
      this.props;
    return (
      <View style={!fullBar && styles.searchContainer}>
        <SearchBar
          value={query}
          onSearchSubmit={this.handleSearchSubmit}
          onSearchQueryChange={this.handleSearchQueryChange}
          onSearchClear={this.handleClearInput}
          onFocus={this.actionShowSuggestions}
          ref={forwardRef}
          {...searchBarProps}
        />
        {!!shouldRenderCancelButton && (
          <ButtonWithText
            onPress={this.actionCancelSearch}
            title="Batal"
            theme="tosca"
            themeType="tertiary"
            small
            style={styles.cancelButtonContainer}
          />
        )}
      </View>
    );
  }

  render() {
    const { FilterComponent, suggestionListStyle, baseURL } = this.props;
    const { query, suggestions, selectedSuggestionIdx, shouldHideSuggestion } =
      this.state;
    return (
      <React.Fragment>
        {this.renderSearchBarWithOrWithoutCancelButton()}
        {!!FilterComponent && (
          <View style={[cStyles.phxxl, cStyles.pbl]}>{FilterComponent}</View>
        )}
        {!!(query.length && suggestions.length && !shouldHideSuggestion) && (
          <SuggestionList
            suggestions={suggestions}
            style={suggestionListStyle}
            onPress={this.handleSearchSubmit}
            selectedSuggestionIdx={selectedSuggestionIdx}
            baseURL={baseURL}
          />
        )}
      </React.Fragment>
    );
  }
}
