import React from 'react';

import { View } from '@dkrm/ui-kit-basic';
import { Modal } from '@dkrm/ui-kit-basic/v2';

import cStyles from '@dkrm/general-libs/theme/styles';
import { K_ROUTE_SEARCH, K_ROUTE_MATTRESS } from 'constants/routeConstants';
import SearchSuggestion from '../SearchSuggestion';

import styles from './styles';

const K_MAIN_SEARCH_BAR_PLACEHOLDER = 'Cari di Dekoruma';

interface SearchSuggestionModalProps {
  visible?: boolean;
  onShow?: Function;
  onSearchCancel?: Function;
  forwardRef?: React.Ref<unknown>;
}

const SearchSuggestionModal: React.FC<SearchSuggestionModalProps> = ({
  visible,
  onShow,
  onSearchCancel,
  forwardRef,
}) => {
  return (
    <Modal
      visible={visible}
      transparent
      ariaHideApp={false}
      animationType="fade"
      onShow={onShow}
    >
      <View style={styles.modalContainer}>
        <SearchSuggestion
          onCancel={onSearchCancel}
          forwardRef={forwardRef}
          config={{
            Kasur: {
              options: {
                isUsingAnchor: false,
              },
              to: K_ROUTE_MATTRESS + K_ROUTE_SEARCH,
            },
          }}
          searchBarProps={{
            showClearButton: true,
            placeholder: K_MAIN_SEARCH_BAR_PLACEHOLDER,
            containerStyle: {
              ...cStyles.prs,
              ...cStyles.flex1,
            },
            textInputStyle: {
              fontSize: 16,
            },
          }}
        />
      </View>
    </Modal>
  );
};

export default SearchSuggestionModal;
