import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { HomepageMeta } from '@dkrm/storefront-commons/components/_abstractComponents/MetaManager';
import colors from '@dkrm/ui-kit-basic/colors';

import { loadProducts } from 'app-libs/redux_modules/flow_modules/search';
import { trackViewedHomepage } from 'app-libs/trackers';

import { Footer } from 'components/Footer';
import MainHome from 'components/home/MainHome';
import FurnitureHomepage from 'components/product/productSearch/@FurnitureHomepage/Loadable';
import ProductSearchPage from 'components/product/productSearch/@ProductSearchPage/Loadable';

import {
  getLoadMoodboardDetailQueryKey,
  loadMoodboardDetail,
} from 'entities/campaign/api';

import { K_MAX_MOODBOARD_PRODUCTS_TO_LOAD } from 'constants/campaignConstants';

import { K_TOP_PRODUCTS_MOODBOARD_ID } from '../MainHome/constants';

const MainHomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    trackViewedHomepage();
  }, []);

  useEffect(() => {
    const preloadProductListPage = () => {
      ProductSearchPage.preload();
      FurnitureHomepage.preload();
    };

    if (window.requestIdleCallback) {
      window.requestIdleCallback(preloadProductListPage);
    } else {
      setTimeout(preloadProductListPage, 5000);
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <HomepageMeta themeColor={colors.C_PRIMARY_TOSCA} />
      <MainHome renderFooter={() => <Footer />} />
    </React.Fragment>
  );
};

MainHomePage.fetchData = async ({ store, queryClient }) => {
  const promises = [];
  try {
    const moodboard = await queryClient.fetchQuery(
      getLoadMoodboardDetailQueryKey(K_TOP_PRODUCTS_MOODBOARD_ID),
      () => loadMoodboardDetail(K_TOP_PRODUCTS_MOODBOARD_ID),
    );
    const moodboardProducts = moodboard.products.slice(
      0,
      K_MAX_MOODBOARD_PRODUCTS_TO_LOAD,
    );
    promises.push(
      store.dispatch(
        loadProducts(moodboardProducts.map((product) => product.upc)),
      ),
    );
  } catch {
    // pass
  }
  return Promise.all(promises);
};

export default withRouter(MainHomePage);
