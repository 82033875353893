import config from 'config';

export const K_INSTAGRAM_FEED_IMAGE_URLS = [
  {
    id: 1,
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/instagram-feed/feed-1.png?auto=webp`,
    linkUrl: 'https://www.instagram.com/dekoruma/',
  },
  {
    id: 2,
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/instagram-feed/feed-2.png?auto=webp`,
    linkUrl: 'https://www.instagram.com/dekoruma/',
  },
  {
    id: 3,
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/instagram-feed/feed-3.png?auto=webp`,
    linkUrl: 'https://www.instagram.com/dekoruma/',
  },
  {
    id: 4,
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/instagram-feed/feed-4.png?auto=webp`,
    linkUrl: 'https://www.instagram.com/dekoruma/',
  },
];
