import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  sectionContainerTop: {
    marginTop: 20,
    ...cStyles.ptl,
  },
  image: {
    ...cStyles.flex1,
    ...cStyles.brs,
    ...cStyles.shadowm,
    overflow: 'hidden',
  },
  title: {
    ...cStyles.phxl,
  },
  subtitle: {
    ...cStyles.mtxs,
    ...cStyles.phxl,
    ...cStyles.mbxl,
  },
  photoListContentContainer: {
    ...cStyles.phm,
  },
});
