import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Voucher } from 'app-libs/components/campaign/types';
import { isOfflineVoucher } from 'app-libs/etc/promoHelper';
import { useAuth } from 'app-libs/hook_modules/auth';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import {
  getExistingCustomerVouchers,
  getNewCustomerVouchers,
  getPublicVouchers,
  isCustomerVoucherLoaded,
  isCustomerVoucherLoading,
  isPublicVoucherLoaded,
  isPublicVoucherLoading,
} from 'app-libs/redux_modules/entity_modules/selectors/voucher';
import {
  loadCustomerVouchers as _loadCustomerVouchers,
  loadPublicVouchers,
} from 'app-libs/redux_modules/entity_modules/voucher';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';

export const useVouchers = () => {
  const dispatch = useDispatch();

  const publicVouchers: Voucher[] = useSelector(getPublicVouchers);
  const isPublicVouchersLoading: boolean = useSelector(isPublicVoucherLoading);
  const isPublicVouchersLoaded: boolean = useSelector(isPublicVoucherLoaded);

  const existingCustomerVouchers: Voucher[] = useSelector(
    getExistingCustomerVouchers,
  );
  const newCustomerVouchers: Voucher[] = useSelector(getNewCustomerVouchers);
  const isCustomerVouchersLoading: boolean = useSelector(
    isCustomerVoucherLoading,
  );
  const isCustomerVouchersLoaded: boolean = useSelector(
    isCustomerVoucherLoaded,
  );

  const { isLoggedIn, isShopAssistant } = useAuth();

  const shownPublicVouchers = useMemo(
    () =>
      publicVouchers.filter(
        (voucher) => isShopAssistant === isOfflineVoucher(voucher),
      ),
    [isShopAssistant, publicVouchers],
  );

  const totalVouchers = useMemo(() => {
    if (isLoggedIn)
      return shownPublicVouchers.length + existingCustomerVouchers.length;
    return shownPublicVouchers.length + newCustomerVouchers.length;
  }, [
    existingCustomerVouchers.length,
    isLoggedIn,
    newCustomerVouchers.length,
    shownPublicVouchers.length,
  ]);

  const loadCustomerVouchers = useCallback(async () => {
    if (isLoggedIn) dispatch(_loadCustomerVouchers());
    else {
      await dispatch(dontShowPromptOnError(loadAuth()));
      dispatch(_loadCustomerVouchers());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (!isCustomerVouchersLoaded) loadCustomerVouchers();
  }, [isCustomerVouchersLoaded, loadCustomerVouchers]);

  useEffect(() => {
    if (!isPublicVouchersLoaded) dispatch(loadPublicVouchers());
  }, [dispatch, isPublicVouchersLoaded]);

  return {
    publicVouchers,
    isPublicVouchersLoading,
    existingCustomerVouchers,
    newCustomerVouchers,
    isCustomerVouchersLoading,
    totalVouchers,
  };
};
