import chunk from 'lodash/chunk';
import React, { memo, useCallback } from 'react';
import { ViewStyle } from 'react-native';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

import CategoryCard from './CategoryCard';
import FillerCard from './FillerCard';

const K_CATEGORY_CARD_PER_ROW = 2;

interface InteriorCategory {
  name: string;
  imageUrl: string;
  linkUrl: string;
  isUsingAnchor?: boolean;
}

interface FillerData {
  name: string;
  linkUrl: string;
  isFillerData: boolean;
  isUsingAnchor?: boolean;
}

interface PortfolioCategorySectionProps {
  title: string;
  subtitle: string;
  categoryData: (InteriorCategory | FillerData)[];
  containerStyle?: ViewStyle;
}

const PortfolioCategorySection: React.FC<PortfolioCategorySectionProps> = memo(
  ({ title, subtitle, categoryData, containerStyle }) => {
    const categoryDataChunk = chunk(categoryData, K_CATEGORY_CARD_PER_ROW);

    const isFillerData = useCallback(
      (data: InteriorCategory | FillerData): data is FillerData => {
        return (data as FillerData).isFillerData !== undefined;
      },
      [],
    );

    return (
      <View style={containerStyle}>
        <Text theme="h3-neutral900" style={cStyles.pb4}>
          {title}
        </Text>
        <Text theme="b5-neutral100" style={cStyles.pb16}>
          {subtitle}
        </Text>
        {categoryDataChunk.map((categoriesPerRow, idxRow) => {
          return (
            <React.Fragment key={idxRow}>
              {idxRow > 0 && <View style={cStyles.pt16} />}
              <View style={s.categoryRow}>
                {categoriesPerRow.map((category, idxCol) => {
                  return (
                    <React.Fragment key={category.name}>
                      {idxCol > 0 && <View style={cStyles.pl8} />}
                      <View style={cStyles.flex1}>
                        {isFillerData(category) ? (
                          <FillerCard
                            name={category.name}
                            linkUrl={category.linkUrl}
                            isUsingAnchor={category.isUsingAnchor}
                          />
                        ) : (
                          <CategoryCard
                            name={category.name}
                            imageUrl={category.imageUrl}
                            linkUrl={category.linkUrl}
                            isUsingAnchor={category.isUsingAnchor}
                          />
                        )}
                      </View>
                    </React.Fragment>
                  );
                })}
              </View>
            </React.Fragment>
          );
        })}
      </View>
    );
  },
);

const s = StyleSheet.create({
  categoryRow: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
  },
});

export default PortfolioCategorySection;
