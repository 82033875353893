import React from 'react';
import chunk from 'lodash/chunk';

import { View, StyleSheet } from '@dkrm/ui-kit-basic';

import cStyles from '@dkrm/general-libs/theme/styles';

import {
  K_HOUSE_CATEGORY_NUM_OF_COL,
  K_HOUSE_CATEGORY_MARGIN,
} from './constants';

import { K_HOUSE_CATEGORIES } from '../constants';

/**
 * @todo: functions need to be moved to etc or upgrade to ui kit component
 */
function getCardMarginHorizontal(col, numColumns, defaultSizeGiven) {
  const size = defaultSizeGiven || 4;
  let marginHorizontal = { marginLeft: size, marginRight: size };

  if ((col + 1) % numColumns === 0) {
    marginHorizontal = { marginLeft: size };
  } else if (col === 0) {
    marginHorizontal = { marginRight: size };
  }
  return marginHorizontal;
}

function getCardMarginVertical(row, numRows, defaultSizeGiven) {
  const size = defaultSizeGiven || 4;
  let marginVertical = { marginTop: size, marginBottom: size };

  if ((row + 1) % numRows === 0) {
    marginVertical = { marginTop: size };
  } else if (row === 0) {
    marginVertical = { marginBottom: size };
  }
  return marginVertical;
}

const categoriesChunk = chunk(K_HOUSE_CATEGORIES, K_HOUSE_CATEGORY_NUM_OF_COL);
const numRows = categoriesChunk.length;

const CategoryGrid = () => {
  const chunkWidth = `calc(${100 /
    K_HOUSE_CATEGORY_NUM_OF_COL}% - ${(K_HOUSE_CATEGORY_MARGIN *
    (K_HOUSE_CATEGORY_NUM_OF_COL * 2 - 2)) /
    K_HOUSE_CATEGORY_NUM_OF_COL}px)`;

  return (
    <View style={s.container}>
      {categoriesChunk.map((chk, row) => (
        <View
          key={row}
          style={[
            s.rowContainer,
            { ...getCardMarginVertical(row, numRows, K_HOUSE_CATEGORY_MARGIN) },
          ]}
        >
          {chk.map(({ CardComponent, props }, col) => {
            return (
              <View
                key={col}
                style={[
                  { width: chunkWidth },
                  {
                    ...getCardMarginHorizontal(
                      col,
                      K_HOUSE_CATEGORY_NUM_OF_COL,
                      K_HOUSE_CATEGORY_MARGIN,
                    ),
                  },
                ]}
              >
                <CardComponent key={col} {...props} />
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.mbxl,
  },
  rowContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
  },
});

export default React.memo(CategoryGrid);
