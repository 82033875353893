import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';

import { SectionHeader } from '../../components';
import {
  K_MARKETPLACE_SECTION_BU_TEXT,
  K_TOP_PRODUCT_SECTION_SUBTITLE,
  K_TOP_PRODUCT_SECTION_TITLE,
} from './constants';

const RetailTitleSection: React.FC = memo(() => {
  return (
    <View style={s.sectionContainerTop}>
      <SectionHeader
        buText={K_MARKETPLACE_SECTION_BU_TEXT}
        buTextStyle={s.buText}
        title={K_TOP_PRODUCT_SECTION_TITLE}
        subtitle={K_TOP_PRODUCT_SECTION_SUBTITLE}
      />
    </View>
  );
});

const s = StyleSheet.create({
  sectionContainerTop: {
    ...cStyles.phxl,
    ...cStyles.mtxl,
  },
  buText: {
    color: '#01B0BA',
  },
});

export default RetailTitleSection;
