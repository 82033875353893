import { StyleSheet } from 'react-native';
import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  modalContainer: {
    ...cStyles.width100,
    ...cStyles.height100,
    position: 'absolute',
    backgroundColor: colors.generateRGBA('black', 0.5),
  },

  searchPlaceholderText: {
    ...cStyles.subtitlem,
    ...cStyles.fwn,
    color: colors.C_BLACK_70,
  },

  searchPlaceholderTouchable: {
    justifyContent: 'center',
  },
});
