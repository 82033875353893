import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { CheckedHouse } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { ViewStyle } from 'types/styles';

interface ExclusiveBrandLabelProps {
  style?: ViewStyle;
}

const K_ICON_SIZE = 12;

const ExclusiveBrandLabel: React.FC<ExclusiveBrandLabelProps> = memo(
  ({ style = null }) => {
    return (
      <View style={[s.exclusiveBrandContainer, style]}>
        <CheckedHouse size={K_ICON_SIZE} style={cStyles.mrs} />
        <Text theme="h7-yellow">Exclusive</Text>
      </View>
    );
  },
);

const s = StyleSheet.create({
  exclusiveBrandContainer: {
    ...cStyles.mvxs,
    ...cStyles.brl,
    ...cStyles.phm,
    ...cStyles.pvs,
    ...cStyles.flexDirRow,
    width: 80,
    backgroundColor: Colors.C_YELLOW_100,
  },
});

export default ExclusiveBrandLabel;
