import React, { memo } from 'react';

import { useAuth } from 'app-libs/hook_modules/auth';

import ULPSectionSkeleton from './ULPSectionSkeleton';
import ULPUnverifiedSection from './ULPUnverifiedSection';
import ULPVerifiedSection from './ULPVerifiedSection';

const ULPSection: React.FC = memo(() => {
  const { user, isLoggedIn, isAuthLoaded } = useAuth();

  if (!isAuthLoaded) return <ULPSectionSkeleton />;

  if (user && isLoggedIn && user.isPhoneNumberVerified)
    return <ULPVerifiedSection uuid={user.uuid} />;

  if (user && isLoggedIn && !user.isPhoneNumberVerified)
    return <ULPUnverifiedSection />;

  // @todo(Michael): change to NewCustomerVoucherSection, deadline: 21 Feb 2024
  return null;
});

export default ULPSection;
