import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { FlatList, ReflowView, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { K_INSTAGRAM_FEED_IMAGE_URLS } from './constants';
import styles from './styles';

const K_PHOTO_WIDTH = 185;
const K_PHOTO_HEIGHT = 239;
const K_DEFAULT_SCREEN_WIDTH = 375;

@connect((state) => ({
  pageWidth: state.dimension.width || K_DEFAULT_SCREEN_WIDTH,
}))
export default class InstagramFeed extends React.PureComponent {
  static propTypes = {
    pageWidth: PropTypes.number,
  };

  static defaultProps = {
    pageWidth: 0,
  };

  keyExtractor = (item) => item.id;

  renderPhoto = ({ item }) => {
    const { pageWidth } = this.props;
    const { linkUrl, imageUrl } = item;
    return (
      <Button to={linkUrl} anchor>
        {() => (
          <View style={[{ width: pageWidth / 2 }]}>
            <ReflowView
              widthProportion={K_PHOTO_WIDTH}
              heightProportion={K_PHOTO_HEIGHT}
            >
              <SmartImage
                source={imageUrl}
                sizes="50vw"
                style={styles.image}
                resizeMode="contain"
              />
            </ReflowView>
          </View>
        )}
      </Button>
    );
  };

  renderPhotos() {
    return (
      <FlatList
        horizontal
        data={K_INSTAGRAM_FEED_IMAGE_URLS}
        contentContainerStyle={styles.photoListContentContainer}
        renderItem={this.renderPhoto}
        ItemSeparatorComponent={() => <View style={{ width: 8 }} />}
        initialNumToRender={3}
        showsHorizontalScrollIndicator={false}
        keyExtractor={this.keyExtractor}
      />
    );
  }

  render() {
    return (
      <View style={styles.sectionContainerTop}>
        <Text theme="h4-black" style={styles.title}>
          Cerita bersama Dekoruma
        </Text>
        <Text theme="body4-black60" style={styles.subtitle}>
          Dari mereka yang telah mewujudkan hunian Japandi impiannya
        </Text>
        {this.renderPhotos()}
      </View>
    );
  }
}
