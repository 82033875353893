import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import ExclusiveBrandLabel from 'app-libs/components/brand/ExclusiveBrandLabel';

import ExclusiveBrandCarousel from './ExclusiveBrandCarousel';

const ExclusiveBrandSection: React.FC = memo(() => {
  return (
    <View style={s.container}>
      <View style={s.titleContainer}>
        <Text theme="h4-neutral900">Brand Eksklusif Dekoruma</Text>
        <ExclusiveBrandLabel />
      </View>
      <Text theme="body4-neutral100" style={cStyles.mhxl}>
        Lengkapi kebutuhan hunianmu dengan berbagai pilihan furnitur dan
        aksesoris bergaya Japandi dari Brand Eksklusif Dekoruma
      </Text>
      <ExclusiveBrandCarousel />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.mtxxl,
  },
  titleContainer: {
    ...cStyles.mbs,
    ...cStyles.mhxl,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
  },
});

export default ExclusiveBrandSection;
