import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

import SearchWithModal from 'app-libs/components/Search/SearchWithModal';
import { getBannersFromAdvertisements } from 'app-libs/etc/advertisementHelper';
import { getAdsByLocation } from 'app-libs/redux_modules/selectors/advertisement';

import { K__STOREFRONT__HOMEPAGE__SEARCH_BAR_ICON } from 'constants/advertisementConstants';
import { K_DEKORUMA_LOGO_WITHOUT_TEXT } from 'constants/assetConstants';

const SearchBar = memo(({ searchBoxStyle }) => {
  const bannerAd = useSelector((state) =>
    getAdsByLocation(state, K__STOREFRONT__HOMEPAGE__SEARCH_BAR_ICON),
  );

  const { bannerUrl } = useMemo(() => {
    const banners = getBannersFromAdvertisements(bannerAd);
    return {
      bannerUrl: banners[0]?.imageUrl || '',
      link: banners[0]?.url || '',
    };
  }, [bannerAd]);

  const logoImageProps = bannerUrl
    ? {
        logoImageUrl: bannerUrl,
        logoImageStyle: s.searchBarLogoImage,
      }
    : {};

  return (
    <View style={cStyles.flex1}>
      <SearchWithModal
        searchBarPlaceholderProps={{
          containerStyle: s.searchBoxContainer,
          searchBoxStyle: [s.searchBox, searchBoxStyle],
          inputTextStyle: s.searchInputText,
          searchIconStyle: s.searchIcon,
          searchIconProps: { size: 24, theme: 'black70' },
          showLogoPlaceholder: true,
          logoImageUrl: K_DEKORUMA_LOGO_WITHOUT_TEXT,
          logoImageStyle: s.logoImage,
          ...logoImageProps,
        }}
      />
    </View>
  );
});

SearchBar.propTypes = {
  searchBoxStyle: stylePropTypes,
};

SearchBar.defaultProps = {
  searchBoxStyle: null,
};

const s = StyleSheet.create({
  searchBoxContainer: {
    ...cStyles.pan,
    ...cStyles.flex1,
    ...cStyles.mll,
    marginRight: 6,
    height: 36,
    zIndex: 2,
  },
  searchBox: {
    ...cStyles.pan,
    ...cStyles.bgw,
    ...cStyles.shadown,
    ...cStyles.bwan,
    height: 36,
    borderRadius: 4,
  },
  searchInputText: {
    ...tStyles.body3,
    color: colors.C_BLACK_60,
  },
  searchIcon: {
    ...cStyles.pll,
  },
  searchBarLogoImage: {
    ...cStyles.mlm,
    width: 58,
    height: 26,
  },
  logoImage: {
    width: 90,
    height: 15,
  },
});

export default SearchBar;
