import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { K_ROUTE_PROMOTION } from 'constants/routeConstants';

const CampaignSliderSeeAllPromoButton = memo(() => (
  <View onMoveShouldSetResponder={() => true} style={s.seeAllContainer}>
    <ButtonWrapper to={K_ROUTE_PROMOTION}>
      {() => (
        <View style={s.seeAll}>
          <Text style={s.seeAllText}>Lihat Voucher</Text>
        </View>
      )}
    </ButtonWrapper>
  </View>
));

const s = StyleSheet.create({
  seeAllContainer: {
    ...cStyles.pam,
    ...cStyles.abs,
    right: 8,
    bottom: 0,
  },
  seeAll: {
    ...cStyles.flexDirRow,
    ...cStyles.phm,
    ...cStyles.pvs,
    backgroundColor: '#006F75',
    alignItems: 'center',
    borderRadius: 4,
  },
  seeAllText: {
    ...tStyles.body5,
    color: colors.C_WHITE_100,
  },
  curve: {
    ...cStyles.abs,
    ...cStyles.bottom,
    ...cStyles.left,
    ...cStyles.right,
  },
});

export default CampaignSliderSeeAllPromoButton;
