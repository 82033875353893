import React, { memo, useRef } from 'react';

import CampaignSliderMain from './Main';

const CampaignSlider = memo(() => {
  const campaignSliderMainRef = useRef();

  return (
    <>
      <CampaignSliderMain campaignSliderMainRef={campaignSliderMainRef} />
    </>
  );
});

export default CampaignSlider;
