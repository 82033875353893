import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import config from 'config';

import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import {
  ButtonWithText,
  Colors,
  Image,
  StyleSheet,
  Text,
  View,
  cStyles,
} from '@dkrm/ui-kit-v2';

import { K_UNM_ACCOUNT_VERIFY_LOGO_URL } from 'constants/ulpConstants';

const K_UNM_VERIFY_ACCOUNT_LOGO_SIZE = 35;

const ULPUnverifiedSection: React.FC = memo(() => {
  const history = useHistory();

  const actionGoToULPMembershipPage = useCallback(() => {
    return actionRouteActionOpenURL(
      config.BLIBLI_TIKET_ACCOUNT_CENTER_URL,
      history,
      {
        shouldOpenNewTab: true,
        isUsingAnchor: true,
      },
    );
  }, [history]);

  return (
    <View style={s.container}>
      <View style={s.descriptionContainer}>
        <Image
          source={K_UNM_ACCOUNT_VERIFY_LOGO_URL}
          alt="unm-account-verify-logo"
          width={K_UNM_VERIFY_ACCOUNT_LOGO_SIZE}
          height={K_UNM_VERIFY_ACCOUNT_LOGO_SIZE}
          resizeMode="contain"
        />
        <Text theme="b4-neutral600" style={s.description}>
          Verifikasi Akun Kamu untuk nikmati keuntungan Blibli x Tiket Points!
        </Text>
      </View>
      <ButtonWithText
        title="Verifikasi"
        themeType="secondary"
        sizeType="small"
        style={s.verifyButton}
        onPress={actionGoToULPMembershipPage}
      />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.br4,
    ...cStyles.shadowOverlay,
    ...cStyles.mt16,
    ...cStyles.mh16,
    ...cStyles.pv8,
    ...cStyles.ph12,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    backgroundImage: `linear-gradient(to bottom right, white, ${Colors.C_TEAL_100})`,
    alignItems: 'center',
  },
  descriptionContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.pl4,
    alignItems: 'center',
    maxWidth: '70%',
  },
  description: {
    ...cStyles.mh12,
  },
  verifyButton: {
    ...cStyles.pv8,
    ...cStyles.ph16,
    ...cStyles.br8,
    ...cStyles.bwam,
    minWidth: 'fit-content',
    height: 'fit-content',
  },
});

export default ULPUnverifiedSection;
