import config from 'config';

export const K_HOUSE_CATEGORY_NUM_OF_COL = 3;
export const K_HOUSE_CATEGORY_MARGIN = 4;

export const K_HOUSE_CATEGORY_CARD_WIDTH = 100;
export const K_HOUSE_CATEGORY_CARD_HEIGHT = 100;

export const K_HOUSE_SELLER_CARD_TITLE = 'Mau Jual Properti ?';
export const K_HOUSE_SELLER_CARD_BANNER_URL = `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/properti/house-seller-card.jpg?auto=webp`;

export const K_HOUSE_CITY_CARD_IMG_WIDTH = 40;
export const K_HOUSE_CITY_CARD_IMG_HEIGHT = 40;
