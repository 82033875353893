import config from 'config';

export const K_BANNER_SLIDER_API_URL = `${config.API_URL_KLAW}/banner-sliders`;
export const K_VOUCHER_SLIDER_API_URL = `${config.API_URL_KLAW}/voucher-sliders`;
export const K_MICROSITE_VOUCHER_SECTIONS_API_URL = `${config.API_URL_GOBLIN}/vouchers/microsite-voucher-sections`;

export const K_ERROR_RESPONSE_CODE_NETWORK_ERROR = 'NETWORK_ERROR';
export const K_ERROR_RESPONSE_CODE_NOT_FOUND = 'NOT_FOUND';
export const K_ERROR_RESPONSE_CODE_SERVER_ERROR = 'SERVER_ERROR';
export const K_ERROR_RESPONSE_CODE_VALIDATION_ERROR = 'VALIDATION_ERROR';
