import config from 'config';

import { Colors } from '@dkrm/ui-kit-basic';

import { K_ROUTE_OFFLINE_STORE_LIST } from 'constants/routeConstants';

export const businessUnitCategoriesTop = [
  {
    title: 'Sofa &\nFurnitur',
    imageSource: {
      uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v3/sofa.png?auto=webp`,
      width: 64,
      height: 32,
    },
    linkProps: { to: '/furniture' },
    backgroundColor: Colors.C_SECONDARY_TOSCA_3,
  },
  {
    title: 'Desain Interior\n& Kitchen Set',
    imageSource: {
      uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v2/desain-interor.png?auto=webp`,
      width: 28,
      height: 21,
    },
    linkProps: {
      to: '/interior',
      isUsingAnchor: true,
    },
    backgroundColor: Colors.C_TERTIARY_YELLOW_3,
  },
  {
    title: 'Beli\nRumah',
    imageSource: {
      uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v3/beli-rumah.png?auto=webp`,
      width: 30,
      height: 28,
    },
    linkProps: { to: '/properti', isUsingAnchor: true },
    additionalBadge: {
      source: {
        uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v3/new-product-small.gif?auto=webp`,
      },
      style: {
        top: -10,
        right: -10,
      },
    },
    backgroundColor: '#F67770',
  },
];

export const businessUnitCategoriesBottom = [
  {
    title: 'Aksesoris\nRumah',
    imageSource: {
      uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v3/AksesorisRumah.png?auto=webp`,
      width: 72,
      height: 36,
    },
    linkProps: { to: '/Aksesoris-Rumah' },
    backgroundColor: Colors.C_SECONDARY_TOSCA_3,
  },
  {
    title: 'Dekoruma\nBusiness',
    imageSource: {
      uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v2/kitchen-set.png?auto=webp`,
      width: 42,
      height: 25,
    },
    linkProps: { to: '/promosi/dekoruma-business-retail' },
    backgroundColor: Colors.C_SECONDARY_TOSCA_3,
  },
  {
    title: 'Lokasi\nShowroom',
    imageSource: {
      uri: `${config.API_URL_MEDIA_CDN}/homepage/story/v3/showroom.png?auto=webp`,
      width: 19,
      height: 19,
    },
    linkProps: {
      to: K_ROUTE_OFFLINE_STORE_LIST,
      isUsingAnchor: false,
    },
    backgroundColor: '#B4FBFF',
  },
];
