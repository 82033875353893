import React, { memo, useCallback, useMemo } from 'react';
import { Dimensions, StyleProp, ViewStyle } from 'react-native';
import { useHistory } from 'react-router-dom';

import config from 'config';

import { K_MOBILE_MAX_WIDTH_S } from '@dkrm/general-libs/theme/styles';
import { DeferredContent } from '@dkrm/ui-kit-basic';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import {
  ButtonWrapper,
  Colors,
  Image,
  StyleSheet,
  Text,
  View,
  cStyles,
} from '@dkrm/ui-kit-v2';
import { Voucher } from '@dkrm/ui-kit-v2/icons';

import { capitalizeText, formatPriceId } from 'app-libs/etc';
import { useVouchers } from 'app-libs/hook_modules/voucher';

import { useQueryLoadMemberLoyalty } from 'entities/ulp/utils';

import { K_ROUTE_PROMOTION } from 'constants/routeConstants';
import { K_BLIBLI_TIKET_POINTS_LOGO_URL } from 'constants/ulpConstants';

import ULPSectionSkeleton from '../ULPSectionSkeleton';

interface ULPVerifiedSectionProps {
  uuid: string;
}

const K_ULP_SECTION_LOGO_SIZE = 10;

const ULPVerifiedSection: React.FC<ULPVerifiedSectionProps> = memo(
  ({ uuid }) => {
    const history = useHistory();

    const {
      data,
      isLoading: isMemberLoyaltyLoading,
      isError,
    } = useQueryLoadMemberLoyalty(uuid);

    const {
      isPublicVouchersLoading,
      isCustomerVouchersLoading,
      totalVouchers,
    } = useVouchers();

    const isLoading =
      isMemberLoyaltyLoading ||
      isPublicVouchersLoading ||
      isCustomerVouchersLoading;

    const isMobileSmall =
      Dimensions.get('window').width <= K_MOBILE_MAX_WIDTH_S;

    const containerStyle: StyleProp<ViewStyle> = useMemo(() => {
      if (isMobileSmall) return s.container;
      return s.containerLarge;
    }, [isMobileSmall]);

    const actionGoToULPRewardsHomePage = useCallback(() => {
      return actionRouteActionOpenURL(
        config.BLIBLI_TIKET_REWARDS_HOMEPAGE_URL,
        history,
        {
          shouldOpenNewTab: true,
          isUsingAnchor: true,
        },
      );
    }, [history]);

    const actionGoToULPVoucherDealsPage = useCallback(() => {
      return actionRouteActionOpenURL(
        config.BLIBLI_TIKET_REWARDS_VOUCHER_DEALS_URL,
        history,
        {
          shouldOpenNewTab: true,
          isUsingAnchor: true,
        },
      );
    }, [history]);

    const actionGoToVoucherListPage = useCallback(() => {
      return actionRouteActionOpenURL(K_ROUTE_PROMOTION, history);
    }, [history]);

    const renderSkeleton = useCallback(() => <ULPSectionSkeleton />, []);

    const renderContent = useCallback(() => {
      return (
        <View style={containerStyle}>
          <ButtonWrapper onPress={actionGoToULPRewardsHomePage}>
            <View style={s.sectionHeaderContainer}>
              <Image
                source={data?.memberLogoUrl || ''}
                alt="ulp-member-logo"
                width={K_ULP_SECTION_LOGO_SIZE}
                height={K_ULP_SECTION_LOGO_SIZE}
                resizeMode="contain"
              />
              <Text theme="b5-neutral100" style={s.sectionHeader}>
                Member Tier
              </Text>
            </View>
            <Text theme="b4-neutral900">
              {capitalizeText(data?.memberTier || '-')}
            </Text>
          </ButtonWrapper>
          <ButtonWrapper onPress={actionGoToULPVoucherDealsPage}>
            <View style={s.sectionHeaderContainer}>
              <Image
                source={K_BLIBLI_TIKET_POINTS_LOGO_URL}
                alt="blibli-tiket-points-logo"
                width={K_ULP_SECTION_LOGO_SIZE}
                height={K_ULP_SECTION_LOGO_SIZE}
                resizeMode="contain"
              />
              <Text theme="b5-neutral100" style={s.sectionHeader}>
                Blibli Tiket Points
              </Text>
            </View>
            <Text theme="b4-neutral900">
              {formatPriceId(data?.activePoint || 0)}
            </Text>
          </ButtonWrapper>
          <ButtonWrapper onPress={actionGoToVoucherListPage}>
            <View style={s.sectionHeaderContainer}>
              <Voucher
                size={K_ULP_SECTION_LOGO_SIZE}
                fill={Colors.C_TEAL_500}
              />
              <Text theme="b5-neutral100" style={s.sectionHeader}>
                Voucher
              </Text>
            </View>
            <Text theme="b4-neutral900">{`${totalVouchers} Voucher`}</Text>
          </ButtonWrapper>
        </View>
      );
    }, [
      actionGoToULPRewardsHomePage,
      actionGoToULPVoucherDealsPage,
      actionGoToVoucherListPage,
      containerStyle,
      data?.activePoint,
      data?.memberLogoUrl,
      data?.memberTier,
      totalVouchers,
    ]);

    if (isError) return null;

    return (
      <DeferredContent
        isLoading={isLoading}
        animation="fade"
        renderSkeleton={renderSkeleton}
        renderContent={renderContent}
      />
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.br4,
    ...cStyles.shadowOverlay,
    ...cStyles.mt16,
    ...cStyles.mh16,
    ...cStyles.pv8,
    ...cStyles.ph12,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    backgroundImage: `linear-gradient(to bottom right, white, ${Colors.C_TEAL_100})`,
  },
  containerLarge: {
    ...cStyles.br4,
    ...cStyles.shadowOverlay,
    ...cStyles.mt16,
    ...cStyles.mh16,
    ...cStyles.pv8,
    ...cStyles.ph24,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    backgroundImage: `linear-gradient(to bottom right, white, ${Colors.C_TEAL_100})`,
  },
  sectionHeaderContainer: {
    ...cStyles.flexDirRow,
    alignItems: 'center',
  },
  sectionHeader: {
    ...cStyles.ml4,
  },
});

export default ULPVerifiedSection;
