import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { View } from 'react-native';

import colors from '@dkrm/general-libs/theme/colors';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text } from '@dkrm/ui-kit-basic';
import ReflowView from '@dkrm/ui-kit-basic/ReflowView';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

export default class FullWidthCard extends Component {
  static propTypes = {
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subsubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    imageUrl: PropTypes.string,
    linkUrl: PropTypes.string,
    imageProportion: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    backgroundImageColor: PropTypes.string,
    imageProps: PropTypes.shape(),
    linkProps: PropTypes.shape(),
    shouldOpenNewTab: PropTypes.bool,
    titleHeadingProps: PropTypes.shape({
      h1: PropTypes.bool,
      h2: PropTypes.bool,
      h3: PropTypes.bool,
      h4: PropTypes.bool,
      h5: PropTypes.bool,
      h6: PropTypes.bool,
    }),
    subtitleHeadingProps: PropTypes.shape({
      h1: PropTypes.bool,
      h2: PropTypes.bool,
      h3: PropTypes.bool,
      h4: PropTypes.bool,
      h5: PropTypes.bool,
      h6: PropTypes.bool,
    }),

    onClick: PropTypes.func /** @DEPRECATED use onPress instead */,
    onPress: PropTypes.func,

    containerStyle: stylePropTypes,
    textContainerStyle: stylePropTypes,
    imageStyle: stylePropTypes,
    captionStyle: stylePropTypes,
    titleStyle: stylePropTypes,
    subtitleStyle: stylePropTypes,
    subsubtitleStyle: stylePropTypes,
    buttonWrapperStyle: stylePropTypes,
  };

  static defaultProps = {
    imageUrl: '',
    linkUrl: null,
    caption: '',
    title: '',
    subtitle: '',
    subsubtitle: '',
    imageProportion: { height: 2, width: 3 },
    backgroundImageColor: colors.C_MEDIUM_LIGHT,
    imageProps: {},
    linkProps: {},
    onClick: () => null,
    onPress: () => null,

    shouldOpenNewTab: false,

    containerStyle: null,
    textContainerStyle: null,
    imageStyle: null,
    captionStyle: null,
    titleStyle: null,
    subtitleStyle: null,
    subsubtitleStyle: null,
    buttonWrapperStyle: null,
    titleHeadingProps: {
      h1: false,
      h2: false,
      h3: false,
      h4: false,
      h5: false,
      h6: false,
    },
    subtitleHeadingProps: {
      h1: false,
      h2: true,
      h3: false,
      h4: false,
      h5: false,
      h6: false,
    },
  };

  onPress = () => {
    const { onPress: _onPress, onClick } = this.props;

    _onPress();

    onClick(); /** @DEPRECATED use onPress props instead */
  };

  renderImageView({
    imageUrl,
    imageStyle,
    imageProportion,
    backgroundImageColor,
    imageProps,
  }) {
    const imageViewStyle = [cStyles.flex1, cStyles.brxs, imageStyle];
    return (
      <ReflowView
        widthProportion={imageProportion.width}
        heightProportion={imageProportion.height}
      >
        {imageUrl ? (
          <ReflowView
            widthProportion={imageProportion.width}
            heightProportion={imageProportion.height}
          >
            <SmartImage
              source={imageUrl}
              sizes="100vw"
              style={imageViewStyle}
              resizeMode="cover"
              {...imageProps}
            />
          </ReflowView>
        ) : (
          <View
            style={[{ backgroundColor: backgroundImageColor }, imageViewStyle]}
          />
        )}
      </ReflowView>
    );
  }

  render() {
    const {
      caption,
      captionStyle,
      containerStyle,
      linkProps,
      linkUrl,
      subsubtitle,
      subsubtitleStyle,
      subtitle,
      subtitleStyle,
      textContainerStyle,
      title,
      titleStyle,
      titleHeadingProps,
      subtitleHeadingProps,
      buttonWrapperStyle,
      ...rest
    } = this.props;

    return (
      <ButtonWrapper
        to={linkUrl}
        onPress={this.onPress}
        linkProps={linkProps}
        hoverableViewStyle={buttonWrapperStyle}
        style={cStyles.height100}
      >
        {() => (
          <View style={[cStyles.flex1, containerStyle]}>
            {this.renderImageView(rest)}
            <View style={[cStyles.pvm, textContainerStyle]}>
              <Text style={[cStyles.mt2, cStyles.captionm, captionStyle]}>
                {caption}
              </Text>
              <Text
                style={[cStyles.mt2, cStyles.h2, titleStyle]}
                {...titleHeadingProps}
              >
                {title}
              </Text>
              <Text
                style={[cStyles.mt2, cStyles.contentm, subtitleStyle]}
                {...subtitleHeadingProps}
              >
                {subtitle}
              </Text>
              <Text style={[cStyles.mts, cStyles.contentxs, subsubtitleStyle]}>
                {subsubtitle}
              </Text>
            </View>
          </View>
        )}
      </ButtonWrapper>
    );
  }
}
