import PropTypes from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import {
  Image,
  Link,
  StyleSheet,
  Text,
  TouchableNativeFeedbackOpacity,
  View,
} from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { trackClickBusinessUnitStory } from 'app-libs/trackers';

const BusinessUnitCard = ({
  style,
  additionalBadge,
  cardWidthProportion,
  cardHeightProportion,
  cardToScreenWidthProportion,
  linkProps,
  title,
  TitleComponent,
  imageSource,
  imageStyle,
  backgroundColor,
}) => {
  const trackClickBusinessUnitCard = () => {
    trackClickBusinessUnitStory(linkProps.to);
  };

  return (
    <View
      direction="horizontal"
      style={[
        s.container,
        cStyles.shadowm,
        cStyles.flex1,
        style,
        { backgroundColor },
      ]}
    >
      {/* @note: We use Link because ButtonWrapper have SSR styling issue */}
      <Link
        style={cStyles.flex1}
        showUnderlineOnHover={false}
        onClick={trackClickBusinessUnitCard}
        {...linkProps}
      >
        <TouchableNativeFeedbackOpacity
          style={[cStyles.flex1, cStyles.height100]}
        >
          <>
            {title ? (
              <Text theme="body4" style={s.title}>
                {title}
              </Text>
            ) : (
              TitleComponent
            )}
            <SmartImage
              source={imageSource}
              shouldPreloadSmallerVersion={false}
              sizes={`${cardToScreenWidthProportion}vw`}
              width={`${(imageSource.width / cardWidthProportion) * 100}%`}
              height={`${(imageSource.height / cardHeightProportion) * 100}%`}
              style={[
                {
                  ...cStyles.abs,
                  bottom: 3,
                  right: 3,
                },
                imageStyle,
              ]}
            />
            {additionalBadge && (
              <Image
                source={additionalBadge?.source}
                shouldPreloadSmallerVersion={false}
                smallerVersionRatio={2}
                style={[
                  {
                    ...cStyles.abs,
                    ...additionalBadge?.style,
                    width: 40,
                    height: 40,
                  },
                ]}
              />
            )}
          </>
        </TouchableNativeFeedbackOpacity>
      </Link>
    </View>
  );
};
BusinessUnitCard.propTypes = {
  style: stylePropTypes,
  gradientColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  linkProps: PropTypes.shape(),
  title: PropTypes.string,
  TitleComponent: PropTypes.element,
  imageSource: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  additionalBadge: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  cardWidthProportion: PropTypes.number.isRequired,
  cardHeightProportion: PropTypes.number.isRequired,
  cardToScreenWidthProportion: PropTypes.number.isRequired,
  imageStyle: stylePropTypes,
  backgroundColor: PropTypes.string,
};

BusinessUnitCard.defaultProps = {
  style: null,
  linkProps: {},
  title: null,
  TitleComponent: null,
  imageStyle: null,
  additionalBadge: null,
  backgroundColor: null,
};

const s = StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.brs,
  },
  title: {
    ...cStyles.mam,
    ...cStyles.fwm,
  },
});

export default BusinessUnitCard;
