import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIError, XhrError } from 'types';

import {
  getLoadBannerSliderQueryKey,
  getLoadHotspotDetailQueryKey,
  getLoadMicrositeDetailQueryKey,
  getLoadMoodboardDetailQueryKey,
  loadBannerSlider,
  loadHotspotDetail,
  loadMicrositeDetail,
  loadMoodboardDetail,
} from './api';
import {
  K_ERROR_RESPONSE_CODE_NETWORK_ERROR,
  K_ERROR_RESPONSE_CODE_NOT_FOUND,
} from './constants';
import {
  BannerSlider,
  LoadHotspotDetailResponse,
  LoadMicrositeDetailResponse,
  LoadMoodboardDetailResponse,
} from './types';

const defaultBannerSlider: BannerSlider = {
  uuid: '',
  name: '',
  dateUpdated: '',
  items: [],
};

const defaultErrorCode = K_ERROR_RESPONSE_CODE_NETWORK_ERROR;
const defaultErrorMessage =
  'Terjadi kesalahan. Laporkan dan coba beberapa saat lagi.';

export const useQueryLoadMicrositeDetail = (
  slug: string,
  options?: UseQueryOptions<LoadMicrositeDetailResponse, APIError>,
) => {
  return useQuery(
    getLoadMicrositeDetailQueryKey(slug),
    () => loadMicrositeDetail(slug),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      ...options,
    },
  );
};

export const useQueryLoadMoodboardDetail = (
  moodboardKey: string,
  options?: UseQueryOptions<LoadMoodboardDetailResponse, APIError>,
) => {
  return useQuery(
    getLoadMoodboardDetailQueryKey(moodboardKey),
    () => loadMoodboardDetail(moodboardKey),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      ...options,
    },
  );
};

export const useQueryLoadHotspotDetail = (
  hotspotKey: string,
  options?: UseQueryOptions<LoadHotspotDetailResponse, APIError>,
) => {
  return useQuery(
    getLoadHotspotDetailQueryKey(hotspotKey),
    () => loadHotspotDetail(hotspotKey),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      ...options,
    },
  );
};

export const useQueryBannerSlider = (uuid: string | undefined) => {
  const { data, isError, isSuccess, isLoading, error } = useQuery(
    getLoadBannerSliderQueryKey(uuid),
    () => loadBannerSlider(uuid || ''),
    {
      enabled: !!uuid,
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
      retry: 2,
      refetchOnWindowFocus: false,
    },
  );

  const bannerSlider = useMemo((): BannerSlider => {
    if (isSuccess && !!data) return data;
    return defaultBannerSlider;
  }, [isSuccess, data]);

  const [errorCode, errorMessage, isNotFound] = useMemo(() => {
    const e = error as XhrError;
    let _errorCode = '';
    let _errorMessage = '';
    let _isNotFound = false;

    if (isError) {
      if (e?.code === K_ERROR_RESPONSE_CODE_NOT_FOUND) {
        _errorCode = e.code;
        _errorMessage = 'Banner Slider tidak ditemukan';
        _isNotFound = true;
      } else {
        _errorCode = defaultErrorCode;
        _errorMessage = defaultErrorMessage;
      }
    }

    return [_errorCode, _errorMessage, _isNotFound];
  }, [error, isError]);

  return {
    bannerSlider,
    isError,
    errorCode,
    errorMessage,
    isLoading,
    isSuccess,
    error: error as XhrError,
    isNotFound,
  };
};
