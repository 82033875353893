import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StarFull } from '@dkrm/icons';
import {
  ReflowView,
  ResponsiveImage,
  Text,
  TouchableOpacity,
  View,
} from '@dkrm/ui-kit-basic';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { ModalEnhanced } from '@dkrm/ui-kit-basic/v2';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { makeParametricUrl } from 'app-libs/etc';
import { useHistory } from 'app-libs/hook_modules/router';
import { closeProductReviewModal } from 'app-libs/redux_modules/entity_modules/review';
import { trackClickedProductReviewModal } from 'app-libs/trackers';

import { K_ROUTE_REVIEW_DETAIL } from 'constants/routeConstants';

import styles from './styles';

const ProductReviewModal = ({ review }) => {
  const { title, orderDate, dateUpdated, image, upc, productReviewKey } =
    review;
  const defaultImage = `${config.API_URL_MEDIA_CDN}/catalogue/${upc}.jpg?auto=webp`;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    dispatch(closeProductReviewModal());
  }, [dispatch]);
  const reviewProduct = (score) => {
    const productReviewLink = makeParametricUrl(K_ROUTE_REVIEW_DETAIL, {
      productReviewKey,
    });
    trackClickedProductReviewModal(upc);
    actionRouteActionOpenURL(
      {
        pathname: productReviewLink,
        state: {
          score,
        },
      },
      history,
    );
  };
  const imageAdditionalQuery = {
    fit: 'bounds',
    auto: 'webp',
    optimize: 'high',
    'trim-color': 'ffffff',
  };
  return (
    <ModalEnhanced
      isVisible={isModalOpen}
      style={styles.reviewModal}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      coverScreen
    >
      <View style={cStyles.bgw}>
        <NavbarTitleCentered
          title="Beri Review"
          leftButtonIcon={NAV_CLOSE}
          leftButtonAction={closeModal}
          titleContainerStyle={cStyles.pvxl}
        />
        <View style={styles.reviewModalContainer}>
          <View style={[cStyles.flexDirRow, cStyles.flexMiddle]}>
            <ReflowView
              style={{ width: 66, height: 66 }}
              widthProportion={1}
              heightProportion={1}
            >
              <ResponsiveImage
                source={{ uri: image || defaultImage }}
                additionalQuery={imageAdditionalQuery}
                style={cStyles.flex1}
                resizeMode="contain"
              />
            </ReflowView>
            <View style={styles.descriptionContainer}>
              <Text theme="h5" numberOfLines={2}>
                {title}
              </Text>
              <Text theme="body4-black35">
                {moment(orderDate || dateUpdated).format('LL')}
              </Text>
            </View>
          </View>
          <View style={[cStyles.mtxl, cStyles.flexMiddle]}>
            <Text theme="h5" style={cStyles.flex1}>
              Bagaimana pengalaman belanja Anda?
            </Text>
          </View>
          <View style={[cStyles.flexMiddle, { marginTop: 14 }]}>
            <View style={[cStyles.flexDirRow, cStyles.flex1]}>
              {[...Array(5).keys()].map((index) => {
                const score = index + 1;
                return (
                  <TouchableOpacity onPress={() => reviewProduct(score)}>
                    <StarFull theme="black30" size={56} />
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
        </View>
      </View>
    </ModalEnhanced>
  );
};

ProductReviewModal.propTypes = {
  review: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    orderDate: PropTypes.string.isRequired,
    dateUpdated: PropTypes.string.isRequired,
    productReviewKey: PropTypes.string.isRequired,
    upc: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductReviewModal;
